<template>
  <section class="auth-phone">
    <van-popup
      v-model="showBindPhone"
      round
      :closeable="closeable"
      :close-on-click-overlay="false"
      position="bottom"
      :style="{ height: '45%' }"
    >
      <section class="bind-phone-wrapper">
        <h1 class="title">{{ $t('XAuth.Phone.309818-0') }}</h1>
        <p class="desc">{{ $t('XAuth.Phone.309818-1') }}</p>
        <section class="input-wrapper">
          <input type="text" :placeholder="$t('XAuth.Phone.309818-2')" v-model="phone" />
          <section class="btn-text" @click="getCode" v-if="!isWaited">{{ $t('XAuth.Phone.309818-3') }}</section>
          <section class="btn-text" v-else>{{ time }}s</section>
        </section>

        <section class="input-wrapper">
          <input type="text" v-model="code" :placeholder="$t('XAuth.Phone.309818-4')" />
        </section>

        <section class="btn-submit" @click="handleBind">
          <span v-if="disableBtn">{{ $t('XAuth.Phone.309818-5') }}</span><span v-else>{{ $t('XAuth.Phone.309818-6') }}</span>
        </section>
      </section>
    </van-popup>
  </section>
</template>
<script>
import api from '@/Api.js'
export default {
  /******************************************
  ** 手机号授权登录
  * @description 手机号授权登录
  * @author stone
  *****************************************
  prop1          <type>            <属性描述>
  *****************************************/
  name: 'phone_auth',
  components: {},
  props: {},
  data() {
    return {
      time: 60,
      isWaited: false,
      closeable: false,
      disableBtn: false,
      showBindPhone: false, // 显示绑定手机号窗口
      phone: '', // 手机号
      code: '' // 验证码
    }
  },
  computed: {},
  methods: {
    async getCode() {
      // 校验手机号
      if (this.phone.length !== 11) {
        this.$toast(this.$t('XAuth.Phone.309818-7'))
        return false
      }
      const res = (await api.sendSmsCode(this.phone)).data
      if (res.message === 'Success') {
        this.isWaited = true
        const timmer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            this.isWaited = false
            this.time = 60
            window.clearInterval(timmer)
          }
        }, 1000)
      }
    },

    /**
     * 处理绑定操作
     */
    async handleBind() {
      // 校验手机号和验证码
      if (this.phone.length !== 11 || this.code.length === 0) {
        this.$toast(this.$t('XAuth.Phone.309818-8'))
        return false
      }
      try {
        if (!this.disableBtn) {
          this.disableBtn = true
          const res = (await api.verifySMSCode(this.phone, this.code)).data
          // 拼接token
          const token = `${res.token_type} ${res.access_token}`
          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', res.refresh_token)
          // 存储过期时间
          const tokenExpireTime = Date.now() + res.expires_in * 1000
          localStorage.setItem('expires_in', tokenExpireTime)
          this.showBindPhone = false
          // vuex存储用户信息
          const user = (await api.getCurrentUser()).data
          this.$store.commit('setUserInfo', user)
          this.disableBtn = false
        }
      } catch (e) {
        console.log('====error', e)
        this.$toast(e.response.data.error_description)
        this.disableBtn = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.auth-phone {
  @media screen and (min-width: 1024px) {
    .van-popup {
      width: 500px;
      left: 50%;
      margin-left: -250px;
    }
  }

  .bind-phone-wrapper {
    padding: 40px 20px;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 30px;
    }
    .desc {
      font-size: 14px;
      color: #999;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .input-wrapper {
      background: #f3f3f3;
      width: 100%;
      height: 45px;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 5px 5px 5px 20px;
        flex: 1;
      }

      .btn-text {
        height: 45px;
        padding: 0 20px;
        display: flex;
        width: 130px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $theme-color;
      }
    }
    .btn-submit {
      background: $theme-color;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      height: 45px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 800;
      cursor: pointer;
    }
  }
}
</style>

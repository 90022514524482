<template>
  <section class="layout-container">
    <template v-if="list.length===0">
      <!-- 空数据状态 -->
      <section class="none-wrapper">
        <van-empty :description="$t('XLayout.Index.982622-0')" />
      </section>
    </template>
    <template v-else>
      <van-list v-model="loadingStatus_"
                :finished="finishedStatus"
                :finished-text="$t('XLayout.Index.982622-1')"
                @load="loadData">

        <!-- 图文矩阵 -->
        <information v-if="layout==='information'"
                     :list="list"
                     :checkMode="checkMode"
                     @preview="preview"></information>

        <!-- 瀑布流 -->
        <waterfall v-if="layout==='waterfall'"
                   :list="list"
                   :checkMode="checkMode"
                   @preview="preview"></waterfall>

        <!-- 智能矩阵 -->
        <intellect v-if="layout==='intellect'"
                   :list="list"
                   :checkMode="checkMode"
                   @preview="preview"></intellect>

        <!-- 时间轴 -->
        <!-- <timeline v-if="layout==='timeline'"
                :list="list"
                @preview="preview"
                :checkMode="checkMode"></timeline> -->

      </van-list>
    </template>
  </section>
</template>
<script>
import { List } from 'vant';
import information from './Information.vue'
import waterfall from './Waterfall.vue'
import timeline from './Timeline.vue'
import intellect from './Intellect.vue'
export default {
  /******************************************
  ** 布局列表组件
  * @description 布局列表
  * @author stone
  *****************************************
  layout            String           布局类型（information： 矩阵，waterfall：瀑布流，intellect： 智能矩阵，timeline： 时间轴）
  list              Array            列表
  *****************************************/
  name: 'x_layout',
  components: {
    information,
    waterfall,
    intellect,
    timeline,
    'van-list': List
  },
  props: {
    layout: String,
    list: Array,
    loadingStatus: Boolean,
    finishedStatus: Boolean,
    checkMode: Boolean,
    finishedText: String
  },
  data () {
    return {
      loadingStatus_: this.loadingStatus
    }
  },
  watch: {
    loadingStatus () {
      this.loadingStatus_ = this.loadingStatus
    }
  },
  computed: {
  },
  methods: {
    loadData () {
      this.$emit('onLoad')
    },
    preview (index) {
      this.$emit('preview', index)
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.layout-container {
  .none-wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-panel {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}
</style>

<template>
  <section class="x-pass">
    <div class="container">
      <h1 class="title" v-if="!disable">
        <template v-if="encrypDisplayInfo.encrypGroup === 'password' && !encrypDisplayInfo.tips">
          {{ $t('XPass.Index.129594-0') }}<br />{{ $t('XPass.Index.129594-1') }}
        </template>
        <template v-else>{{ encrypDisplayInfo.tips }} </template>
      </h1>
      <h1 class="title" v-else>{{ $t('XPass.Index.129594-2') }}<br />{{ $t('XPass.Index.129594-3') }}</h1>
      <h2 class="tips">{{ tips }}</h2>

      <div class="input-pass" :class="{ shake: shake }" v-if="!disable">
        <input id="pass" autofocus :placeholder="$t('XPass.Index.129594-4')" v-model="text" />
      </div>
      <div class="confirm" @click="handleConfirm" v-if="encrypDisplayInfo.encrypGroup === 'password'">
        {{ $t('XPass.Index.406297-0') }}
      </div>
      <div class="copyright">{{ $t('XPass.Index.129594-5') }}</div>
    </div>
  </section>
</template>
<script>
export default {
  /******************************************
   * 加密访问组件
   * @description 用于加密访问的组件
   * @author name
   *****************************************/
  name: 'x-pass',
  components: {},
  props: {},
  data() {
    return {
      text: '',
      tips: '',
      shake: false
    }
  },
  computed: {
    encrypDisplayInfo() {
      return this.$store.state.encrypDisplayInfo
    },
    liveId() {
      return this.$route.params.id
    },
    disable() {
      return this.$store.state.encrypDisplayInfo.encrypGroup === 'mine'
    }
  },
  methods: {
    handleConfirm() {
      console.log(this.$t('XPass.Index.129594-6'), this.encrypDisplayInfo)
      let pwArr = []
      try {
        pwArr = JSON.parse(this.encrypDisplayInfo.passwords)
        if (!Array.isArray(pwArr)) {
          pwArr = [`${JSON.parse(this.encrypDisplayInfo.passwords)}`]
        }
      } catch (e) {
        pwArr = [`${this.encrypDisplayInfo.passwords}`]
      }
      console.log('-----', this.encrypDisplayInfo, pwArr)
      // 触发相应的事件

      if (pwArr.includes(this.text)) {
        localStorage.setItem(`pass-${this.liveId}`, this.text)
        this.tips = this.$t('XPass.Index.129594-7')
        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        this.shake = true
        this.tips = this.$t('XPass.Index.129594-8')

        setTimeout(() => {
          this.shake = false
          this.text = ''
        }, 500)
      }
      setTimeout(() => {
        this.tips = ''
      }, 1500)
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    console.log('----', this.encrypDisplayInfo)
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto'
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
@keyframes gradient {
  from {
    background-position: 0% center;
  }
  to {
    background-position: 100% center;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.confirm {
  background: $theme-color;
  color: #fff;
  height: 50px;
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
}
.x-pass {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .container {
    width: 300px;
    // 屏幕宽度大于768 padding-top：40vh
    // 屏幕宽度小于768 padding-top：20vh
    @media (min-width: 768px) {
      padding-top: 35vh;
    }
    @media (max-width: 768px) {
      padding-top: 20vh;
    }
    box-sizing: border-box;

    .tips {
      height: 40px;
      line-height: 40px;
    }

    .title {
      font-size: 28px;
      font-weight: 900;
      line-height: 45px;
      // background: linear-gradient(to right, #00003d, #005aa4);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    .copyright {
      font-size: 12px;
      color: #999;
      text-align: center;
      margin-top: 20px;
      position: fixed;
      bottom: 40px;
      width: 100%;
      text-align: center;
      left: 0;
    }

    .input-pass {
      margin-top: 40px;
      background: #efefef;
      border-radius: 10px;
      height: 65px;
      line-height: 65px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      input {
        background: transparent;
        border: 0;
        width: 290px;
        height: 65px;
        line-height: 65px;
        font-size: 20px;
        font-weight: 900;
        color: #000;

        &::placeholder {
          // background: linear-gradient(90deg, #000000, #5c5c5c);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // animation: gradient 5s ease infinite;
        }
      }
    }
  }
}
</style>

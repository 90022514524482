<template>
  <section class="preview-container">
    <!-- 头部菜单栏 start -->
    <transition name="toTop">
      <section class="header pd30" v-if="showMenu" @touchmove.prevent.stop>
        <!-- 导航栏 | 现在只保留一个关闭了 -->
        <div class="nav-header flex-r">
          <!-- 返回按钮 -->
          <div class="back wrapper flex-r" @click="close">
            <x-icon type="arrow-left-s-line"></x-icon>
          </div>

          <!-- 数量 -->
          <div class="count" style="opacity: 1">{{ curIndex + 1 }} / {{ total }}</div>

          <!-- info -->
          <div class="info wrapper flex-r" @click="close" style="opacity: 0">
            <x-icon type="close-fill"></x-icon>
          </div>
        </div>
      </section>
    </transition>
    <!-- 头部菜单栏 end -->

    <!-- 外层嵌套用于下滑关闭 start -->
    <swiper ref="swiper_outer" class="swiper-wrapper flex-r" :options="swiperOpt_1">
      <swiper-slide>
        <!-- 真实图片轮播列表 start -->
        <swiper ref="swiper_inner" :options="swiperOpt_2">
          <swiper-slide
            v-for="(slide, vi) in virtualData.slides"
            :key="vi"
            :style="{ left: `${virtualData.offset}px` }"
          >
            <div class="swiper-zoom-container">
              <img class="pic" :key="vi" :src="doUrl(slide.url)" />
            </div>
          </swiper-slide>
        </swiper>
        <!-- 真实图片轮播列表 end -->
      </swiper-slide>
    </swiper>
    <!-- 外层嵌套用于下滑关闭 end -->

    <!-- 底部菜单栏 start -->
    <transition name="toBottom">
      <section class="footer" v-if="showMenu" @touchmove.prevent.stop>
        <!-- 文本描述 -->
        <section class="hot-content" v-if="list[curIndex] && list[curIndex].content">
          <p class="title">{{ list[curIndex].content.title || '' }}</p>
          <p class="desc">{{ list[curIndex].content.content || '' }}</p>
          <section class="wrapper">
            <!-- 外链 -->
            <section
              class="link"
              v-if="list[curIndex].content.linkTitle && list[curIndex].content.linkUrl"
              @click="linkTo(list[curIndex].content.linkUrl)"
            >
              <x-icon type="link"></x-icon>
              {{ list[curIndex].content.linkTitle }}
            </section>
            <!-- 视频 -->
            <section
              class="video"
              v-if="list[curIndex] && list[curIndex].content && list[curIndex].content.videoUrl"
              @click="play(list[curIndex].content.videoUrl)"
            >
              <x-icon type="live"></x-icon>
              {{ $t('XPreview.Index.155253-0') }}
            </section>
          </section>
        </section>

        <!-- 工具栏 -->
        <section class="bar">
          <!-- 下载 -->
          <section class="item download" v-if="!userInfo.id" @click="dLogin">
            {{ $t('XPreview.Index.155253-2') }}
          </section>
          <section class="item download" v-else>
            {{ showWX ? $t('XPreview.Index.155253-3') : $t('XPreview.Index.155253-2') }}
            <div class="wx-link" @click="showWX = false" v-if="showWX" v-html="linkToWeapp"></div>
            <div class="wx-mask" v-else @click="download"></div>
          </section>

          <!-- {{ $t('XPreview.Index.155253-1') }} -->
          <section class="item origin" v-show="!list[curIndex].isOrigin" @click="viewOrigin">
            {{ $t('XPreview.Index.155253-1') }}
          </section>
          <!-- 序号 -->
          <section class="item index" @click="handleLike">
            <x-like
              :id="list[curIndex].id"
              @unlike="unlike"
              @like="like"
              :count="list[curIndex].likes"
              :state="list[curIndex].likeState"
            ></x-like>
          </section>
        </section>

        <!-- 操作按钮栏 end -->
      </section>
    </transition>
    <!-- 底部菜单栏 end -->

    <!-- 人脸识别组件 -->
    <x-face
      v-show="
        showFace && showAIFace && showMenu && list[curIndex] && list[curIndex].faces && list[curIndex].faces.length > 0
      "
      :list="list[curIndex].faces"
    ></x-face>

    <!-- ------------------弹出窗口 -->
    <van-popup v-model="showPicInfo" round position="bottom" :style="{ height: '30%' }" />

    <van-popup v-model="showPlayer" round closeable position="bottom" :style="{ height: '40%' }">
      <section class="player-wrapper">
        <x-player v-if="showPlayer" :src="playUrl" poster=""></x-player>
      </section>
    </van-popup>
  </section>
</template>
<script>
import XImage from '@/components/XImage/Index'
import XFace from '@/components/XFace/Index'
import XLike from '@/components/XLike/Index'
import XPlayer from '@/components/XPlayer/Index'
import api from '@/Api'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  /******************************************
  ** 图片预览组件
  * @description 图片预览
  * @author stone
  *****************************************
  list          Array            预览图片数组
  curIndex      Number           当前显示第几张
  total         Number           总照片数
  *****************************************/
  name: 'preview',
  components: {
    Swiper,
    XLike,
    SwiperSlide,
    XImage,
    XFace,
    XPlayer
  },
  props: {
    list: Array,
    curIndex: Number,
    total: Number,
    showFace: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    const _this = this
    return {
      showPlayer: false,
      playUrl: '',
      diffY: 0,
      startY: 0,
      moveY: 0,
      showA: true,
      showB: false,
      showC: false,
      loop: true,
      showWX: false,
      code: '',
      t: '',
      showMenu: true,
      showPicInfo: false,
      swiperOpt_1: {
        direction: 'vertical',
        touchRatio: 2,
        on: {
          touchStart: function (e) {
            if (e.touches && e.touches.length === 1) {
              _this.startY = e.touches[0].clientY
            }
          },
          touchMove: function (e) {
            if (e.touches && e.touches.length === 1) {
              _this.moveY = e.touches[0].clientY
              _this.diffY = Math.abs(_this.moveY - _this.startY)
              _this.$emit('diff', _this.diffY)
              if (_this.diffY > 200) {
                // 关闭当前轮播
                _this.close()
              }
            }
          },
          touchEnd: function (e) {
            if (_this.diffY < 200) {
              _this.diffY = 0
              _this.$emit('diff', _this.diffY)
            } else {
              _this.$toast(this.$t('XPreview.Index.155253-4'))
            }
          }
        }
      },
      swiperOpt_2: {
        zoom: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        initialSlide: _this.curIndex,
        on: {
          tap: function (e) {
            _this.showMenu = !_this.showMenu
          },
          zoomChange: function (scale, imageEl, slideEl) {
            _this.viewOrigin()
          },
          slideChange() {
            _this.$emit('onSlideChange', this.activeIndex)
          }
        },
        virtual: {
          slides: _this.list,
          renderExternal: this.virtualRenderExternal
        }
      },
      virtualData: {}
    }
  },
  watch: {
    userInfo() {
      this.getUserCode()
    }
  },
  computed: {
    noWatermark() {
      return this.$route.query.wm ? this.$route.query.wm.indexOf('none') !== -1 : false
    },
    enableWatermark() {
      return this.$store.state.config.enableWatermark
    },
    liveInfo() {
      return this.$store.state.liveInfo
    },
    watermark() {
      let url = this.$store.state.liveInfo.tempObj.waterStr
      let re = /(imageMogr2\/)([^|]*)/g // 正则表达式匹配imageMogr2后到下一个'|'之前的部分

      if (url.match(re)) {
        // 如果url中包含'imageMogr2/'，则替换'imageMogr2/'后到'|'之前的部分为'thumbnail/1200x'
        url = url.replace(re, '$1thumbnail/1200x')
      } else {
        // 如果url中不包含'imageMogr2/'，则在url参数部分后面添加'&imageMogr2/thumbnail/1200x|imageslim'
        let index = url.indexOf('&')
        url = url.slice(0, index + 1) + 'imageMogr2/thumbnail/1200x|imageslim&' + url.slice(index + 1)
      }

      return url
    },
    linkToWeapp() {
      return `<wx-open-launch-weapp
            class="openweapp"
            id="saveBtn2"
            username="gh_49eacc118770"
            path="PackageSpace/transferFile/transferFile?code=${this.code}"
          >
            <script type="text/wxtag-template">
              <style>.btn{
                          width: 1000px;
                          height: 1000px;
                          }</style>
              <button class="btn"></button>
            <\/script>
          </wx-open-launch-weapp>`
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    showAIFace() {
      return this.$store.state.liveInfo.tempObj.temp?.ai || false
    }
  },
  methods: {
    // 初始化轮播模板
    virtualRenderExternal(data) {
      this.virtualData = data
    },
    like() {
      this.list[this.curIndex].likeState = true
      this.list[this.curIndex].likes++
    },
    unlike() {
      this.list[this.curIndex].likeState = false
      this.list[this.curIndex].likes--
    },
    dLogin() {
      this.$doAuth()
      this.t = setInterval(async () => {
        if (this.userInfo.id) {
          await this.getUserCode()
          clearInterval(this.t)
        }
      }, 500)
    },
    linkTo(url) {
      window.location.href = url
    },
    async getUserCode() {
      if (!this.userInfo.id) {
        this.$doAuth()
      } else {
        this.code = (await api.getUserAuthCode()).data.result
      }
    },
    handleLike() {},
    async download() {
      let arr = []
      // 判断是否登录
      if (!this.userInfo.id) {
        this.$doAuth()
      } else {
        await this.getUserCode()
        arr.push({
          name: this.list[this.curIndex].name,
          url: this.list[this.curIndex].wUrl.replace('type=R1000wWS', 'type=RtW')
        })
        // 拼接传递给小程序的参数
        let data = {
          saveType: 'person', // 'team' or 'person'
          live: {
            id: this.$route.params.id,
            name: this.liveInfo.name
          },
          list: arr
        }
        const res = await api.setAppData(JSON.stringify(data))
        // this.$toast('启动中，请再次点击')
        // document.getElementById('saveBtn2').click()
        this.showWX = true
        setTimeout(() => {
          this.showWX = false
        }, 3000)
      }
    },
    play(url) {
      this.playUrl = url
      this.showPlayer = true
    },

    modifyUrl(url) {
      // 计算 'watermark/1' 在字符串中出现的次数
      let count = (url.match(/watermark\/1/g) || []).length

      // 如果 'watermark/1' 出现次数大于1
      if (count > 1) {
        // 使用正则替换字符串中第一个 'watermark/1' 为 'watermark/3'
        url = url.replace(/watermark\/1/, 'watermark/3')

        // 将后续的 '|watermark/1' 替换为 ''
        url = url.replace(/\|watermark\/1/g, '')
      }

      return url
    },
    processUrl(link) {
      // 使用正则表达式替换匹配到的字符串
      let processedLink = link
        .replace(/\&imageMogr2\/auto-orient\/thumbnail\/1200x\|imageslim/g, '')
        .replace(/\/thumbnail\/1200x/g, '')
        .replace(/imageMogr2\|/g, '')
        .replace(/\|imageslim/g, '')
      processedLink = this.modifyUrl(processedLink)
      return processedLink
    },
    doUrl(str) {
      console.log('----doUrl', str, this.noWatermark, this.enableWatermark)
      let url = ''
      let ourl = ''
      if (this.enableWatermark && !this.noWatermark) {
        ourl = str + '&' + this.watermark
        url = encodeURI(str + '&' + this.watermark)
      } else {
        ourl = str + '&imageMogr2/auto-orient/thumbnail/1200x|imageslim'
        url = encodeURI(str + '&imageMogr2/auto-orient/thumbnail/1200x|imageslim')
      }
      if (this.list[this.curIndex].isOrigin) {
        url = encodeURI(this.processUrl(ourl))
      }
      return url
    },
    viewOrigin() {
      // console.log('chakanyuantu', this.list, this.curIndex)
      this.list[this.curIndex].isOrigin = true
    },
    // 关闭
    close() {
      this.$emit('close')
    },
    getPicInfo() {
      this.showPicInfo = true
    },
    haha() {
      console.log('haha')
    }
  },
  destroyed() {
    clearInterval(this.t)
  },
  async mounted() {
    if (this.userInfo.id) {
      await this.getUserCode()
    }
  }
}
</script>
<style lang="scss" scoped>
.preview-container {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  color: #fff;
  justify-content: center;
  align-items: center;
  backdrop-filter: saturate(180%) blur(30px);

  .close {
    text-align: center;
    margin-top: 40px;
  }

  .pic {
    width: 100%;
    position: relative;
    z-index: 9;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    height: 200px;
    padding-top: 10px;
    // pointer-events: none;

    .nav-header {
      justify-content: space-between;
      align-items: center;

      .wrapper {
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        display: flex;

        .icon {
          font-size: 35px;
        }
      }
    }
  }

  .swiper-container {
    height: 100%;
  }

  .loading-wrapper {
    background: red;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 10;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    .download {
      position: relative;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      .link,
      .video {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 14px;
        color: #fff;
        z-index: 10;
      }
      .video {
        margin-left: 10px;
      }
    }

    .hot-content {
      position: absolute;
      bottom: 80px;
      // background: red;
      overflow: hidden;
      width: 100%;
      left: 0;
      padding: 0 15px;
      box-sizing: border-box;

      .title {
        font-size: 15px;
        line-height: 30px;
        font-weight: 500;
      }

      .desc {
        font-size: 13px;
        line-height: 25px;
        font-weight: 200;
      }
    }

    .view-origin {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      height: 70px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .right-wrapper {
      display: flex;
      justify-content: space-between;

      .download-btn,
      .share-btn {
        background: rgba(0, 0, 0, 0.5);
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: 20px;
        position: relative;
      }
    }

    .wx-link {
      opacity: 0;
      // background: red;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 50px;
      z-index: 1;
    }

    .wx-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      z-index: 2;
    }

    .tips {
      justify-content: center;

      .tips-wrapper {
        display: flex;
        padding: 20px 30px;
        box-sizing: border-box;
        border-radius: 18px;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.5);
        width: 340px;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 10px;
        }
      }
    }

    .btn-wrapper {
      height: 100px;
      justify-content: space-around;
      align-items: center;
      list-style: none;
      margin-top: 40px;

      .btn-item {
        justify-content: center;
        align-items: center;

        .text {
          margin-top: 20px;
        }
      }
    }

    .swiper-wrapper {
      height: 100%;
      width: 100%;
      position: absolute;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;

      .swiper-container {
        min-height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
      }
    }

    .pic-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .pic {
        width: 100%;
      }
    }
    .bar {
      width: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      position: fixed;
      bottom: 30px;
      left: 0;

      .item {
        padding: 8px 15px;
        border-radius: 3px;
        border: 1px solid rgba(100, 100, 100, 0.3);
      }
    }
  }
  .player-wrapper {
    padding: 50px 10px;
  }
}
</style>

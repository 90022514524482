<template>
  <section class="intellect-container">
    <section class="container">
      <section class="wrapper" v-for="(item, index) in newList" :key="index">
        <template v-if="!item.hidden">
          <!-- 大图 -->
          <template v-if="index % 10 === 0">
            <section v-if="!item.hidden" class="max-wrapper">
              <section class="like-wrapper" v-show="item.likes > 0">
                <!-- 点赞数量 -->
                <x-like :enableClick="false" :id="item.id" :count="item.likes" :state="item.likeState"></x-like>
              </section>
              <x-image
                :src="item.wUrl"
                @click.native="preview(index)"
                :enableWatermark="enableWatermark"
                :checked="item.isChecked"
                :checkMode="checkMode"
                :width="maxW"
                :height="getH(item.width, item.height)"
                @onChecked="check(index)"
              ></x-image>
            </section>
          </template>
          <!-- 小图 -->
          <template v-else>
            <section v-if="!item.hidden" class="min-wrapper">
              <section class="like-wrapper" v-show="item.likes > 0">
                <!-- 点赞数量 -->
                <x-like :enableClick="false" :id="item.id" :count="item.likes" :state="item.likeState"></x-like>
              </section>
              <x-image
                :src="item.wUrl"
                :enableWatermark="enableWatermark"
                :checked="item.isChecked"
                :width="minPhotoWidth"
                :height="minPhotoWidth"
                :checkMode="checkMode"
                @click.native="preview(index)"
                @onChecked="check(index)"
              ></x-image>
            </section>
          </template>
        </template>
      </section>
    </section>
  </section>
</template>
<script>
import XImage from '@/components/XImage/Index'
import XLike from '@/components/XLike/Index'
export default {
  /******************************************
  ** AI智能布局
  * @description 就是一张大图后面跟9个小图
  * @author stone
  *****************************************
  prop1          <type>            <属性描述>
  *****************************************/
  name: 'layout_intellect',
  components: { XImage, XLike },
  props: {
    list: Array,
    checkMode: Boolean
  },
  data() {
    return {}
  },
  computed: {
    minPhotoWidth() {
      return (window.innerWidth - 8) / 3
    },
    noWatermark() {
      return this.$route.query.wm === 'none'
    },

    enableWatermark() {
      return this.$store.state.config.enableWatermark && !this.noWatermark
    },
    newList() {
      return this.list.filter((item) => {
        return !item.hidden
      })
    },
    maxW() {
      return window.innerWidth
    },
    checkPhotoList() {
      return this.$store.state.checkPhotoList
    }
  },
  methods: {
    preview(index) {
      this.$emit('preview', index)
    },
    check(index) {
      if (!this.list[index].isChecked) {
        if (this.checkPhotoList.length < 9) {
          // 添加照片
          let arr = this.checkPhotoList
          arr.push(this.list[index])
          this.$store.commit('setCheckPhotoList', arr)
          this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
        } else {
          alert(this.$t('XLayout.Intellect.996767-0'))
        }
      } else {
        // 移除照片
        let arr = this.checkPhotoList
        let newArr = arr.filter((item) => {
          return item.id !== this.list[index].id
        })
        this.$store.commit('setCheckPhotoList', newArr)
        this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
      }
    },
    getH(w, h) {
      return (h / w) * this.maxW
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.intellect-container {
  width: 100%;
  overflow: hidden;

  .container {
    width: 103%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .wrapper {
      .max-wrapper {
        margin-bottom: 4px;
        position: relative;

        .like-wrapper {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 12px;
          padding: 5px 10px;
          background: #0000002c;
          color: #fff;
          z-index: 1;
          border-radius: 3px;
        }
      }
      .min-wrapper {
        margin-bottom: 4px;
        margin-right: 4px;
        position: relative;

        .like-wrapper {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 12px;
          padding: 5px 10px;
          background: #0000002c;
          color: #fff;
          z-index: 1;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>

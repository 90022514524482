import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/live/:id',
    name: 'index',
    component: () => {
      //判断手机端
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        return import('../views/mobile/Simple.vue')
      } else {
        return import('../views/pc/Default.vue')
      }
    }
  },
  {
    path: '/live/:id/puzzle',
    name: 'puzzle',
    component: () => import('../components/XShot/Puzzle.vue')
  },
  {
    path: '/live/:id/face/:faceId',
    name: 'face',
    component: () => import('../components/XFace/Result.vue')
  },
  {
    path: '/live/:id/user',
    name: 'user',
    component: () => {
      //判断手机端
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        return import('../components/XUser/Index.vue')
      } else {
        return import('../views/pc/Default.vue')
      }
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../components/XAuth/Wechat.vue')
  },
  {
    // 会匹配所有路径
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // }
})

export default router

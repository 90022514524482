<template>
  <section class="face-container">
    <!-- 悬浮组件 -->
    <section class="face-wrapper">
      <section class="header">
        <span class="ai">{{ $t('XFace.Index.388709-0') }}</span>{{ $t('XFace.Index.388709-1') }}
      </section>
      <section class="main">
        <!-- 向左收缩 -->
        <!-- <section class="scale">
          <x-icon type="yp-arrow-left-s-line"></x-icon>
        </section> -->
        <!-- 用户头像 -->
        <section class="list">
          <van-swipe :loop="false" :width="60" :show-indicators="false">
            <van-swipe-item v-for="item in list" :key="item.faceKey">
              <section
                class="avatar"
                :style="{
                  backgroundImage: `url(${item.faceUrl + '&type=R300wWS'})`
                }"
                @click="getCurrentFace(item.personId)"
              ></section>
            </van-swipe-item>
          </van-swipe>
        </section>
      </section>
    </section>
  </section>
</template>
<script>
import { Swipe, SwipeItem } from 'vant'
import XLayout from '@/components/XLayout/Index'
export default {
  /******************************************
  ** 人脸识别组件
  * @description 人脸识别
  * @author stone
  *****************************************
  prop1          <type>            <属性描述>
  *****************************************/
  name: 'x_face',
  components: {
    XLayout,
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem
  },
  props: {
    list: Array
  },
  data() {
    return {
      currentFace: {}
    }
  },
  computed: {},
  methods: {
    getCurrentFace(faceId) {
      this.$router.push({
        name: 'face',
        params: {
          id: this.$route.params.id,
          faceId: faceId
        }
      })
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.face-container {
  position: fixed;
  left: 0;
  width: 100%;
  top: 70px;
  z-index: 9999;
  .face-wrapper {
    padding: 0 10px;
    .header {
      display: inline-flex;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .ai {
        padding: 3px 3px;
        background: $theme-color;
        border-radius: 3px;
        color: #fff;
        font-size: 12px;
        margin-right: 5px;
      }
    }
    .main {
      // border: 1px solid $theme-color;
      // background: rgba(0, 0, 0, 0.2);
      // backdrop-filter: saturate(180%) blur(30px);
      // border-radius: 0 10px 10px 10px;
      display: flex;
      align-items: center;
      height: 65px;
      .scale {
        width: 25px;
        height: 60px;
        background: $theme-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 0 10px;
      }
      .list {
        flex: 1;
        overflow: hidden;
        padding: 0 5px;
        .avatar {
          width: 50px;
          height: 50px;
          background-color: rgba(255, 255, 255, 0.1);
          // background: $theme-color;
          border-radius: 50%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .face-list {
    header {
      padding: 50px 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar {
        width: 50px;
        height: 50px;
        background: $theme-color;
        border-radius: 50%;
        background-size: contain;
        background-position: center center;
      }
    }
  }
}
</style>

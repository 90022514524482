<template>
  <div id="yaopai">
    <x-pass @pass="handlePass" v-if="showPass"></x-pass>
    <router-view v-else />
    <!-- 微信分享组件 -->
    <x-share ref="wechatShare" :options="wechatShareInfo"></x-share>
    <!-- dingding分享 -->
    <div class="ding-share" @click="dingShare" v-if="inDing && closeDing">
      <icon
        type="icon-guanbi"
        class="i"
        style="font-size: 10px; padding: 10px"
        @click.native.stop="closeDing = false"
      ></icon
      >钉钉分享
    </div>
    <!-- 手机号登录 -->
    <x-auth-phone ref="phoneAuth"></x-auth-phone>
    <!-- 格乐利雅弹窗 -->
    <x-glly ref="glly"></x-glly>
  </div>
</template>

<script>
import Vue from 'vue'
import XPass from '@/components/XPass/Index.vue'
import XAuthPhone from '@/components/XAuth/Phone.vue'
import XGlly from '@/components/XGlly/Index.vue'
import XShare from '@/components/XShare/Index'
import api from '@/Api.js'
import * as dd from 'dingtalk-jsapi'
export default {
  components: {
    XShare,
    XGlly,
    XAuthPhone,
    XPass
  },
  data() {
    return {
      pList: [],
      closeDing: true,
      showPage: false,
      wechatShareInfo: {
        shareImgUrl: '',
        shareTitle: '',
        shareDescription: '',
        shareLink: ''
      },
      scrollDirection: null,
      lastScrollTop: 0,
      showPass: false
    }
  },
  watch: {
    liveId() {
      this.init()
    },
    liveInfo() {
      document.title = this.liveInfo.name
    },
    currentPhotoCategory() {
      // console.log('~~~~~~currentPhotoCategory变了')
      // this.getPhotoList()
      this.initPhotoListStatus()
    },
    currentPhotoPage() {
      // console.log('~~~~~~currentPhotoPage变了')
      this.loadMorePhotoList()
    },
    currentPhotoSortOrder() {
      // console.log('~~~~~~currentPhotoSortOrder变了')
      this.getPhotoList()
      this.initPhotoListStatus()
    },
    themeInfo() {
      // 主题配置
      document.getElementsByTagName('body')[0].style.setProperty('--themeColor', this.themeInfo.themeColor || '#006fff')
      if (this.themeInfo.themeType === 'default_light') {
        import('@/styles/theme/default_light.scss')
      } else if (this.themeInfo.themeType === 'default_dark') {
        import('@/styles/theme/default_dark.scss')
      } else {
        // 默认
        import('@/styles/theme/default_light.scss')
      }
    }
  },
  computed: {
    encrypDisplayInfo() {
      return this.$store.state.encrypDisplayInfo
    },
    inDing() {
      return dd.version !== undefined
    },
    shareInfo() {
      return this.$store.state.liveInfo.sharer || {}
    },
    liveId() {
      return this.$route.params.id
    },
    agentInfo() {
      return this.$store.state.agentInfo
    },
    liveInfo() {
      return this.$store.state.liveInfo
    },
    currentPhotoCategory() {
      return this.$store.state.status.currentPhotoCategory
    },
    currentPhotoSortOrder() {
      return this.$store.state.status.currentPhotoSortOrder
    },
    currentPhotoPage() {
      return this.$store.state.status.currentPhotoPage
    },
    photoList() {
      return this.$store.state.photoList
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    themeInfo() {
      return this.$store.state.liveInfo.tempObj ? this.$store.state.liveInfo.tempObj.themeInfo || {} : {}
    },
    noWatermark() {
      return this.$route.query.wm === 'none'
    },

    enableWatermark() {
      return this.$store.state.config.enableWatermark && !this.noWatermark
    },

    watermark() {
      return this.$store.state.liveInfo.tempObj ? this.$store.state.liveInfo.tempObj.waterStr || 'type=R1000wWS' : ''
    }
  },
  methods: {
    handlePass() {
      this.showPass = false
    },
    // 钉钉分享
    dingShare() {
      // dingding钉钉分享接口调用
      dd.ready(() => {
        // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
        dd.biz.util.share({
          type: 0,
          url: window.location.href,
          title: this.liveInfo.sharer.title,
          content: this.liveInfo.sharer.description,
          image: this.liveInfo.sharer.logo
        })
      })
    },
    touchmove(e) {
      // console.log('touchmove', e.touches[0].clientY)
      // const clientY = e.touches[0].clientY
      // this.$store.commit('setIsScrolling', true)
      // document.title = 'touchmove'
    },

    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > this.lastScrollTop) {
        // 向下滚动
        if (this.scrollDirection !== 'down') {
          // console.log('向下滚动中')
          this.scrollDirection = 'down'
          this.$store.commit('setIsScrolling', 'down')
        }
      } else if (scrollTop < this.lastScrollTop) {
        // 向上滚动
        if (this.scrollDirection !== 'up') {
          // console.log('向上滚动中')
          this.scrollDirection = 'up'
          this.$store.commit('setIsScrolling', 'up')
        }
      }
      clearTimeout(this.scrollEndTimeoutId)
      this.scrollEndTimeoutId = setTimeout(() => {
        // console.log('停止滚动')
        this.scrollDirection = null
        this.$store.commit('setIsScrolling', false)
      }, 100)
      this.lastScrollTop = scrollTop
    },

    // 初始化
    async init() {
      this.getCurrentUser() // 获取当前登录用户信息
      await this.getLiveInfo()
      this.loadStatistics() // 载入访问统计
      this.getViewCount()
      this.initShare() // 载入分享
      this.getAgentInfo() // 获取客户经理信息
      this.getOwnerInfo() // 获取活动所有者信息
      this.getPhotoCategory()
      this.getPhotoList()
      this.getHotpointList()
    },
    async refreshUserToken() {
      const refreshToken = window.localStorage.getItem('refresh_token')
      // console.log(refreshToken)
      // 如果存在refresh_token且在60天有效期内
      if (refreshToken) {
        try {
          const res = (await api.refreshUserToken(refreshToken)).data
          // 拼接token
          const token = `${res.token_type} ${res.access_token}`
          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', res.refresh_token)
          // 存储过期时间
          const tokenExpireTime = Date.now() + res.expires_in * 1000
          localStorage.setItem('expires_in', tokenExpireTime)
          this.getCurrentUser()
        } catch (e) {
          // 刷新token失败
          console.log('refresh_token 失败')
          let log = ''
          console.log('An error occurred:')
          if (e.response && e.response.data && e.response.data.message) {
            console.log('Server Error Message:', e.response.data.message)
            log = e.response.data.message
          } else if (e.response) {
            console.log('Error Status:', e.response.status)
            console.log('Error Data:', e.response.data)
            log = e.response.status + ' | ' + e.response.data
          } else {
            console.log('Error Message:', e.message)
            log = e.message
          }

          api.setLog('error', log, {
            module: '刷新refreshToken错误',
            error: e,
            time: new Date()
          })
          return false
        }
      } else {
        this.$store.commit('setUserInfo', {})
      }
    },
    async getCurrentUser() {
      // vuex存储用户信息
      try {
        const user = (await api.getCurrentUser()).data
        const res = await api.addViewer(this.liveId)
        // console.log('=====', res)
        this.$store.commit('setUserInfo', user)
      } catch (e) {
        this.refreshUserToken()
      }
    },
    async getViewCount() {
      const res = (
        await api.getStatistics({
          serviceId: 'LiveAlbum',
          dataId: this.liveId
        })
      ).data
      this.$store.commit('setViewCount', res.pv)
    },
    loadStatistics() {
      const src = `${process.env.VUE_APP_BASE_API}/statistics/unionid/LiveAlbum\/${this.liveId}?referer=Other`
      // 创建script标签，引入访问统计脚本
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      document.getElementsByTagName('head')[0].appendChild(script)
    },
    // 切换分类后，初始化列表的loading状态和finished状态
    initPhotoListStatus() {
      this.$store.commit('setCurrentPhotoPage', 0)
      this.$store.commit('setPhotoListLoadingFinished', false)
      this.$store.commit('setPhotoListLoading', true)
    },
    initShare() {
      console.log(this.$route)
      if (this.$route.name === 'face') {
        this.wechatShareInfo.shareImgUrl = this.shareInfo.logo || 'https://assets.aiyaopai.com/default_screen2%2Fb1.png'
        this.wechatShareInfo.shareTitle = 'LightIO AI人脸识别结果'
        this.wechatShareInfo.shareDescription = '快看！我在这场活动中找到了谁~'
        this.wechatShareInfo.shareLink = window.location.href
      } else {
        this.wechatShareInfo.shareImgUrl = this.shareInfo.logo || 'https://assets.aiyaopai.com/default_screen2%2Fb1.png'
        this.wechatShareInfo.shareTitle = this.shareInfo.title || this.liveInfo.name
        this.wechatShareInfo.shareDescription = this.shareInfo.description || '点击查看详情'
        this.wechatShareInfo.shareLink = window.location.href
      }
      this.$refs.wechatShare.init()
    },
    // 获取活动所属人/团队
    async getOwnerInfo() {
      if (this.liveInfo.userId) {
        // 活动归属个人
        const res = (await api.getUserInfo(this.liveInfo.userId)).data
        res.type = 'user'
        this.$store.commit('setOwnerInfo', res)
      } else {
        // 活动归属团队
        const res = (await api.getTeamInfo(this.liveInfo.teamId)).data
        res.type = 'team'
        this.$store.commit('setOwnerInfo', res)
      }
    },
    // 获取客户经理信息
    async getAgentInfo() {
      const { agent } = this.liveInfo
      if (agent && agent.serviceManagerId) {
        let res = await api.getAgentInfo(agent.serviceManagerId)
        if (!res.data.disabled) {
          this.$store.commit('setAgentInfo', res.data)
        }
      }
    },

    isNotApril19_2024() {
      const now = new Date() // 获取当前日期和时间
      const year = now.getFullYear() // 获取当前年份
      const month = now.getMonth() // 获取当前月份（注意：月份是从0开始计算的，0代表1月，11代表12月）
      const day = now.getDate() // 获取当前日

      // 判断当前日期是否不是2024年4月19日
      if (year !== 2024 || month !== 3 || day !== 19) {
        return true // 如果当前日期不是2024年4月19日，则返回true
      } else {
        return false // 如果当前日期是2024年4月19日，则返回false
      }
    },

    // 获取活动详情
    async getLiveInfo() {
      let res = (await api.getLiveInfo(this.$route.params.id)).data
      let tobj = {}
      if (res.id) {
        try {
          res.tempObj = JSON.parse(res.templates)
        } catch (e) {
          console.error('解析发生错误', e)
        }
        console.log('----------------------liveInfo', res)
        if (res.teamId === '8094SJFG0A00' && this.isNotApril19_2024()) {
          // 弹出格乐利雅窗口
          this.$nextTick(() => {
            setTimeout(() => {
              // this.$store.commit('setShowGGLYAd', true)
              this.$refs.glly.init()
            }, 100)
          })
        }
        this.$store.commit('setLiveInfo', res)
        // 设置人脸搜索模式
        const searchGlobal = res.tempObj ? res.tempObj.faceGlobalActivitySearch || {} : {}

        this.$store.commit('setFaceGlobalActivitySearch', searchGlobal.enable)
        // 设置默认列表展示方式
        const temp = res.tempObj ? res.tempObj.temp || {} : {}
        // console.log('temp:', res)
        this.$store.commit('setEncrypDisplayInfo', res.tempObj.encrypDisplayInfo)

        this.$store.commit('setTotalPhotoCount', res.storageStatistics.livePictureCount)
        const pinfo = res.tempObj.encrypDisplayInfo
        const password = localStorage.getItem(`pass-${this.liveId}`)

        if (pinfo) {
          if (pinfo.enable) {
            if (pinfo.encrypGroup === 'password') {
              let pwdArr = []
              try {
                pwdArr = JSON.parse(pinfo.passwords)
                if (!Array.isArray(pwdArr)) {
                  pwdArr = [`${JSON.parse(pinfo.passwords)}`]
                }
              } catch (e) {
                pwdArr = [`${pinfo.passwords}`]
              }
              console.log('======888', pwdArr, pinfo)
              if (pwdArr.includes(password)) {
                this.showPass = false
              } else {
                this.showPass = true
              }
            } else if (pinfo.encrypGroup === 'all') {
              this.showPass = false
            } else {
              this.showPass = true
            }
          } else {
            this.showPass = false
          }
        } else {
          this.showPass = false
        }

        const layout = temp.layout
        const layouts = temp.layouts
        if (layout) {
          if (layouts === '1') {
            // 瀑布流
            this.$store.commit('setCurrentPhotoLayout', 'waterfall')
          }
          if (layouts === '2') {
            // 信息流
            this.$store.commit('setCurrentPhotoLayout', 'information')
          }
          if (layouts === '3') {
            // ai布局
            this.$store.commit('setCurrentPhotoLayout', 'intellect')
          }
        }
        // console.log('=======565', res.tempObj.waterStr)
        if (res.tempObj.waterStr) {
          // 有水印
          this.$store.commit('setEnableWatermark', true)
        } else {
          this.$store.commit('setEnableWatermark', false)
        }
      } else {
        this.$router.replace({
          name: '404'
        })
      }
    },

    // 获取活动分类
    async getPhotoCategory() {
      let res = (await api.getPhotoCategory(this.$route.params.id)).data.result
      this.$store.commit('setPhotoCategory', res)
    },
    // 加载更多图片列表
    async loadMorePhotoList() {
      if (this.currentPhotoPage === 0) {
        // 清空数据
        this.pList = []
        this.$store.commit('setPhotoList', [])
      }
      this.$store.commit('setPhotoListLoading', true)
      let res = (
        await api.getLivePhotoList({
          offset: this.currentPhotoPage * 100,
          limit: 100,
          hidden: false,
          albumId: this.$route.params.id,
          categoryId: this.currentPhotoCategory === 'all' ? '' : this.currentPhotoCategory,
          sortOrder: this.currentPhotoSortOrder
        })
      ).data
      if (this.currentPhotoCategory === 'all') {
        // this.$store.commit('setTotalPhotoCount', res.total)
      }
      this.$store.commit('setCurrentCategoryPhotoCount', res.total)
      // 获取点赞状态
      let likeStates
      if (this.userInfo && this.userInfo.id) {
        likeStates = (await api.getLikeState(res.result.map((item) => item.id))).data.result
      }
      res.result.map((item, index) => {
        this.$set(item, 'isChecked', false)
        this.$set(item, 'isOrigin', false)
        if (this.userInfo && this.userInfo.id) {
          this.$set(item, 'likeState', likeStates[index].existing)
        }
        this.$set(item, 'wUrl', this.enableWatermark ? item.url + '&' + this.watermark : item.url + '&type=R1000wWS')
        this.pList.push(item)
      })

      this.$store.commit('setPhotoList', this.pList)
      this.$store.commit('setPhotoListLoading', false)
    },
    // 获取图片列表
    async getPhotoList() {
      this.pList = []
      this.$store.commit('setPhotoList', [])
      this.$store.commit('setPhotoListLoading', true)
      let res = (
        await api.getLivePhotoList({
          offset: this.currentPhotoPage * 100,
          limit: 100,
          hidden: false,
          albumId: this.$route.params.id,
          categoryId: this.currentPhotoCategory === 'all' ? '' : this.currentPhotoCategory,
          sortOrder: this.currentPhotoSortOrder
        })
      ).data
      if (this.currentPhotoCategory === 'all') {
        // this.$store.commit('setTotalPhotoCount', res.total)
      }
      this.$store.commit('setCurrentCategoryPhotoCount', res.total)
      // 获取点赞状态
      let likeStates
      if (this.userInfo && this.userInfo.id) {
        likeStates = (await api.getLikeState(res.result.map((item) => item.id))).data.result
      }
      res.result.map((item, index) => {
        this.$set(item, 'isChecked', false)
        this.$set(item, 'isOrigin', false)
        if (this.userInfo && this.userInfo.id) {
          this.$set(item, 'likeState', likeStates[index].existing)
        }
        this.$set(item, 'wUrl', this.enableWatermark ? item.url + '&' + this.watermark : item.url + '&type=R1000wWS')
      })
      this.pList = res.result
      this.$store.commit('setPhotoList', this.pList)
      this.$store.commit('setPhotoListLoading', false)
    },
    async getHotpointList() {
      let res = (
        await api.getLivePhotoList({
          offset: this.currentPhotoPage * 100,
          limit: 100,
          hot: true,
          hidden: false,
          albumId: this.$route.params.id,
          sortOrder: 'Descending'
        })
      ).data.result
      this.$store.commit('setHotpointList', res)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    clearTimeout(this.scrollEndTimeoutId)
  },
  mounted() {
    // console.log(this.liveInfo)
    // 全局刷新
    Vue.prototype.$refreshPhotoList = () => {
      this.pList = []
      this.initPhotoListStatus()
      this.getPhotoList()
    }
    // 授权登录
    Vue.prototype.$doAuth = (bool = true) => {
      if (!localStorage.getItem('access_token')) {
        if (!/MicroMessenger/i.test(window.navigator.userAgent)) {
          // 非微信环境
          this.$refs.phoneAuth.closeable = bool
          this.$refs.phoneAuth.showBindPhone = true
        } else {
          this.$refs.phoneAuth.closeable = bool
          this.$refs.phoneAuth.showBindPhone = true
          // 微信环境
          //if (window.location.origin.indexOf('192') !== -1) {
          //this.$refs.phoneAuth.closeable = bool
          // this.$refs.phoneAuth.showBindPhone = true
          //} else {
          // this.$router.push({
          //  path: '/auth'
          // })
          //}
        }
      } else {
        this.$refs.phoneAuth.closeable = bool
        this.$refs.phoneAuth.showBindPhone = true
      }
    }

    // 检查微信环境
    Vue.prototype.$checkWechatEnv = () => {
      if (!/MicroMessenger/i.test(window.navigator.userAgent)) {
        // 非微信环境
        this.$toast('请在微信环境中打开')
        return false
      }
    }

    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
.ding-share {
  height: 30px;
  background: #0066ff;
  position: absolute;
  display: flex;
  align-items: center;
  opacity: 0.9;
  top: 20px;
  left: 0;
  padding: 5px 15px 5px 0px;
  color: #fff;
  font-size: 13px;
  z-index: 499;
  box-sizing: border-box;
  border-radius: 0 30px 30px 0;
}
.none {
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>

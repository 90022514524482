/******************************************************
 * api文档地址：https://docs.apipost.cn/preview/8b22584bc1f75d22/5b2bf1ab44e22483?target_id=5885fa16-a2cb-4c53-9086-4d8aed4329d5#06b8a5ef-c023-4d48-820e-883e32f9b069
 * author: stone
 * ****************************************************
 */
import request from '@/utils/request.js'
import axios from 'axios'
import qs from 'qs'

axios.defaults.withCredentials = true

export default {
  /**
   * 获取直播图片列表
   * @param {*} options
   * @returns
   */
  getLivePhotoList: (params) => {
    return request({
      url: '/livepicture/list',
      method: 'get',
      params
    })
  },

  // 记录海报生成
  recordPoster: (albumId) => {
    return request({
      url: '/posterstatistics/generates',
      method: 'post',
      data: {
        albumId
      }
    })
  },

  setLog: (level, message, additionalInfo) => {
    return request({
      url: 'https://front-analysis.aiyaopai.com/log',
      method: 'post',
      data: {
        level: level,
        message: message,
        additionalInfo: additionalInfo
      }
    })
  },

  /**
   * 点赞发布照片
   * @param {*} picId 
   * @returns 
   */
  likeLivePicture: picId => {
    return request({
      url: '/likes/livepictures/' + picId,
      method: 'put'
    })
  },


  /**
   * 取消点赞发布照片
   * @param {*} picId 
   * @returns 
   */
  unlikeLivePicture: picId => {
    return request({
      url: '/likes/livepictures/' + picId,
      method: 'delete'
    })
  },

  /**
  * 获取发布照片是否点赞
  */
  getLikeState: idArr => {
    return request({
      url: `/likes/livepictures/exists`,
      method: 'get',
      params: {
        sourceIds: idArr
      }
    })
  },

  /**
   * 访客服务接口
   * @param {} liveId
   * @returns
   */
  addViewer: (liveId) => {
    return request({
      url: '/livealbumview',
      method: 'put',
      data: {
        dataId: liveId,
        originType: 'H5'
      }
    })
  },

  /**
   * 获取直播详情信息
   * @param {*} albumId
   * @returns
   */
  getLiveInfo: (albumId) => {
    return request({
      url: `/livealbum/${albumId}`
    })
  },

  /**
   * 获取直播列表
   * @param {*} openId
   * @returns
   */
  getLiveList: (params) => {
    return request({
      url: '/livealbum/albumids',
      method: 'get',
      params
    })
  },

  /**
   * 获取用户授权登录码
   * @param {*} openId
   * @returns
   */
  getUserAuthCode: (openId) => {
    return request({
      url: '/user/authorizationCode',
      method: 'get'
    })
  },

  /**
   * 传递应用数据
   * @param {*} data
   * @returns
   */
  setAppData: (info) => {
    return request({
      url: '/appnavigation',
      method: 'post',
      data: {
        data: info
      }
    })
  },

  /**
   * 获取直播分类
   * @param {*} albumId
   * @returns
   */
  getPhotoCategory: (albumId) => {
    return request({
      url: '/livepicturecategory/list',
      params: {
        albumId
      }
    })
  },

  /**
   * 获取用户信息
   * @param {*} id
   * @returns
   */
  getUserInfo: (id) => {
    return request({
      url: '/user/' + id,
      method: 'get'
    })
  },

  /**
   * 检测是否关注活动所有人/团队
   * @param {*} id
   * @returns
   */
  followCheck: (id) => {
    return request({
      url: `/follow/check?toIds[]=${id}`,
      method: 'get'
    })
  },

  /**
   * 获取团队信息
   * @param {*} id
   * @returns
   */
  getTeamInfo: (id) => {
    return request({
      url: '/team/' + id,
      method: 'get'
    })
  },

  /**
   * 获取弹幕列表
   * @param {*} offset
   * @param {*} limit
   * @returns
   */
  getBulletList: (offset, limit, dataId) => {
    return request({
      url: '/bulletcomments/list',
      method: 'get',
      params: {
        offset: offset,
        limit: limit,
        dataId: dataId,
      }
    })
  },



  /**
 * 获取服务器时间
 */
  getServerTime: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          'https://liveclientapiv2.dev.aiyaopai.com',
          qs.stringify({
            api: 'SystemInformation.Get',
            fields: 'Time'
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          console.error(
            '===========> getServerTime 接口异常：',
            err.response ? err.response.data.Message : err
          )
          reject(err)
        })
    })
  },

  /**
   * 格乐利雅 邀请手机号
   * @param {*} offset
   * @param {*} limit
   */
  initiativeRecommend: (phoneNo, liveId) => {
    return request({
      url: '/initiativerecommend',
      method: 'post',
      data: {
        phoneNo: phoneNo,
        albumId: liveId
      }
    })
  },

  /**
   * 发送弹幕
   * @param {*} offset
   * @param {*} limit
   */
  createBullet: (dataId, content, createAt) => {
    return request({
      url: '/bulletcomments',
      method: 'post',
      data: {
        dataId: dataId,
        content: content,
        createAt: createAt
      }
    })
  },

  /**
   * 校验登录验证码
   */
  verifySMSCode(phoneNo, code) {
    return request({
      url: '/oauth/connect/token',
      method: 'post',
      data: qs.stringify({
        client_id: 'web',
        grant_type: 'sms',
        countryCode: '86',
        phoneNo,
        code
      })
    })
  },

  /**
   * 登陆微信code
   * @param {*} code
   * @returns
   */
  loginWithWxCode: (code) => {
    return request({
      method: 'post',
      url: '/oauth/connect/token',
      data: qs.stringify({
        client_id: 'web',
        grant_type: 'wechat_authorization_code',
        appId: 'wx1d26d449c607f655',
        code
      })
    })
  },

  /**
   * 获取当前用户信息
   * @returns
   */
  getCurrentUser: () => {
    return request({
      url: '/user/current'
    })
  },

  //账户绑定微信应用
  bindWechatApp: (openId) => {
    return request({
      method: 'post',
      url: '/user/wechatapp',
      data: {
        openId,
        appId: 'wx1d26d449c607f655'
      }
    })
  },

  //账户解绑微信应用
  deleteWxApp: (openId) => {
    return request({
      method: 'delete',
      url: '/user/wechatapp',
      data: {
        openId,
        appId: 'wx1d26d449c607f655'
      }
    })
  },

  //发送验证码
  sendSmsCode: (phoneNo) => {
    return request({
      method: 'post',
      url: '/oauth/smsconnect/code',
      data: qs.stringify({
        countryCode: 86,
        phoneNo
      })
    })
  },

  /**
   * 刷新用户令牌
   * @param {*} refreshToken
   */
  refreshUserToken: (refreshToken) => {
    return request({
      method: 'post',
      url: '/oauth/connect/token',
      data: qs.stringify({
        client_id: 'web',
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      })
    })
  },

  //手机验证码登录
  loginWithSmsCode: (phoneNo, code, serviceManagerId) => {
    return request({
      method: 'post',
      url: '/oauth/connect/token',
      data: qs.stringify({
        grant_type: 'sms',
        client_id: 'web',
        countryCode: 86,
        phoneNo,
        code,
        serviceManagerId
      })
    })
  },

  /**
   * 报名检测
   * @param {*} formId
   * @returns
   */
  enrollCheck: (formId) => {
    return request({
      url: `/registrationdata/checkfilled/${formId}`
    })
  },

  /**
   * 获取当前用户
   * @returns
   */
  getCurrentUser: () => {
    return request({
      url: '/user/current'
    })
  },

  /**
   * 获取经理信息
   * @param {*} agentId
   * @returns
   */
  getAgentInfo: (agentId) => {
    return request({
      url: '/manager/' + agentId
    })
  },

  getWxVerifyData: () => {
    //字符串转base64
    function encode(str) {
      // 对字符串进行编码
      var encode = encodeURI(str)
      // 对编码的字符串转化base64
      var base64 = btoa(encode)
      return base64
    }
    return request({
      url: '/wechat/JsApi/signature',
      method: 'get',
      params: {
        appId: 'wx1d26d449c607f655',
        url: encode(window.location.href)
      },
      showLoading: false
    })
  },

  /**
   * 获取访问统计信息
   * @param {*} params
   * @returns
   */
  getStatistics: (params) => {
    return request({
      url: '/statistics/statistics',
      method: 'get',
      params
    })
  },

  /*
 获取人脸列表
 参数:
 params.personId:人脸ID
 */
  getFaceList: (params) => {
    return request({
      url: '/livepicture/facelist',
      method: 'get',
      params
    })
  },

  // 创建红包
  createRedPacket: (data) => {
    return request({
      method: 'post',
      url: '/marketing/redEnvelope',
      data: data
    })
  },

  //通过红包id获取支付数据
  getPayDataByRedPacketId: (redEnvelopeId) => {
    return request({
      url: '/marketing/redEnvelopeOrder/payToken',
      method: 'get',
      params: {
        redEnvelopeId
      }
    })
  },

  //我的红包列表
  getMyRedpacketList: (params) => {
    return request({
      url: '/marketing/redenvelope/mine/list',
      method: 'get',
      params
    })
  },

  //直播相册红包列表
  getLiveRedpacketList: (params) => {
    return request({
      url: '/marketing/redEnvelope/list',
      method: 'get',
      params
    })
  },

  //抢红包
  grabRedPacket: (id) => {
    return request({
      url: '/marketing/redenvelope/grab/' + id,
      method: 'get'
    })
  },

  //查询红包详情
  getRedPacketPacketDetail: (id) => {
    return request({
      url: '/marketing/redenvelope/' + id,
      method: 'get'
    })
  },

  //红包领取记录
  getRedPacketGrabDetail: (RedEnvelopeId) => {
    return request({
      url: '/marketing/redenveloperecord/list',
      method: 'get',
      params: {
        RedEnvelopeId
      }
    })
  }
}

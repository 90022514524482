<template></template>
<script>
import api from '@/Api'
export default {
  name: 'x_share',
  props: {
    options: {
      type: Object,
      default () {
        return {
          shareImgUrl: '',
          shareTitle: '',
          shareDescription: '',
          shareLink: ''
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
  },
  methods: {
    init () {
      let _this = this
      /* eslint-disable */
      api.getWxVerifyData().then(res => {
        // 初始化页面信息
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx1d26d449c607f655', // 必填，企业号的唯一标识，此处填写企业号corpid
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          openTagList: ["wx-open-launch-weapp"],
          jsApiList: [
            'openLocation', 'wx-open-launch-weapp', 'updateAppMessageShareData', 'updateTimelineShareData', 'previewImage', 'chooseWXPay'
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(function () {
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          wx.updateTimelineShareData({
            title: _this.options.shareTitle, // 分享标题
            link: _this.options.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _this.options.shareImgUrl, // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
              // alert('分享成功')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
              // alert('取消分享')
            }
          })

          wx.updateAppMessageShareData({
            title: _this.options.shareTitle, // 分享标题
            desc: _this.options.shareDescription, // 分享描述
            link: _this.options.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: _this.options.shareImgUrl, // 分享图标
            // type: '', // 分享类型,music、video或link，不填默认为link
            // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              // 用户确认分享后执行的回调函数
              // alert('分享成功')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
              // alert('取消分享')
            }
          })
        })
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
</style>

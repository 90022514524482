<template>
  <section class="timeline-container"></section>
</template>
<script>
export default {
  /******************************************
  ** 时间轴布局
  * @description 时间轴布局
  * @author stone
  *****************************************
  prop1          <type>            <属性描述>
  *****************************************/
  name: 'timeline',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>

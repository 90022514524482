import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true, //关闭
  state: {
    theme: '',
    hasLoad: false,
    menuAll: true, // 默认显示全部
    liveInfo: {},
    agentInfo: {},
    userInfo: {},
    ownerInfo: {},
    photoCategory: [],
    userInfo: {},
    encrypDisplayInfo: {},
    hotpointList: [],
    photoList: [],
    checkPhotoList: [],
    menuList: [],
    status: {
      currentPage: 0, // 首页
      launchFull: false,
      showGGLYAd: false,
      hasLoadingOnce: false, // loading是否已经加载过一次了
      pageTab: 1, // 主要用于simple主题，0代表查看活动详情，1代表查看照片直播
      isScrolling: false, // 页面是否正在滚动
      currentPhotoCategory: 'all', // 当前照片分类
      currentPhotoSortOrder: 'Descending', // 当前照片排序
      currentPhotoLayout: 'information', // 当前照片显示布局
      currentPhotoPage: 0, // 当前照片加载的页数
      totalPhotoCount: 0, // 全部总照片数
      currentCategoryPhotoCount: 0, // 当前所选分类照片总数
      photoListLoading: false, // 照片列表加载状态，控制无限下滑加载的状态
      photoListLoadingFinished: false, // 照片列表加载完毕状态
      photoCheckMode: false, // 选择图片模式
      viewCount: 0, // 浏览量
      // ======
      previewMode: false, // 预览图片模式
      currentPreviewPhotoIndex: 0 // 当前预览图片的Index
    },
    config: {
      enableWatermark: false, // 启用水印
      enableAd: true, // 启用广告
      faceGlobalActivitySearch: false, // 人脸全局活动搜索
      enablePuzzle: false, // 启用拼图
      enableService: false // 启用客服
    }
  },
  getters: {},
  mutations: {
    setTheme(state, res) {
      state.theme = res
    },
    setMenuAll(state, res) {
      state.menuAll = res
    },
    setEncrypDisplayInfo(state, res) {
      state.encrypDisplayInfo = res
    },
    setOwnerInfo(state, res) {
      state.ownerInfo = res
    },
    setMenuList(state, res) {
      state.menuList = res
    },
    setHasLoad(state, res) {
      state.hasLoad = res
    },
    setFaceGlobalActivitySearch(state, res) {
      state.config.faceGlobalActivitySearch = res
    },
    setEnableWatermark(state, res) {
      state.config.enableWatermark = res
    },
    setPageTab(state, res) {
      state.status.pageTab = res
    },
    setShowGGLYAd(state, res) {
      state.status.showGGLYAd = res
    },
    setHasLoadingOnce(state, res) {
      state.status.hasLoadingOnce = res
    },
    setIsScrolling(state, res) {
      state.status.isScrolling = res
    },
    setCheckPhotoList(state, res) {
      state.checkPhotoList = res
    },
    setUserInfo(state, res) {
      state.userInfo = res
    },
    setAgentInfo(state, res) {
      state.agentInfo = res
    },
    setCurrentPage(state, res) {
      state.status.currentPage = res
    },
    setLaunchFull(state, res) {
      state.status.launchFull = res
    },
    setViewCount(state, res) {
      state.status.viewCount = res
    },
    setLiveInfo(state, res) {
      state.liveInfo = res
    },
    setPhotoCheckMode(state, res) {
      state.status.photoCheckMode = res
    },
    setPhotoCategory(state, res) {
      state.photoCategory = res
    },
    setCurrentPhotoCategory(state, res) {
      state.status.currentPhotoCategory = res
    },
    setCurrentPhotoSortOrder(state, res) {
      state.status.currentPhotoSortOrder = res
    },
    setCurrentPhotoLayout(state, res) {
      state.status.currentPhotoLayout = res
    },
    setTotalPhotoCount(state, res) {
      state.status.totalPhotoCount = res
    },
    setCurrentCategoryPhotoCount(state, res) {
      state.status.currentCategoryPhotoCount = res
    },
    setCurrentPhotoPage(state, res) {
      state.status.currentPhotoPage = res
    },
    setPhotoList(state, res) {
      state.photoList = res
    },
    setHotpointList(state, res) {
      state.hotpointList = res
    },
    setPhotoListLoading(state, res) {
      state.status.photoListLoading = res
    },
    setPhotoListLoadingFinished(state, res) {
      state.status.photoListLoadingFinished = res
    },
    setPreviewMode(state, res) {
      state.status.previewMode = res
    },
    setCurrentPreviewPhotoIndex(state, res) {
      state.status.currentPreviewPhotoIndex = res
    }
  },
  actions: {},
  modules: {}
})

const WaitForImagesPlugin = {
  install(Vue) {
    Vue.prototype.$waitForImages = async function (urls) {
      return new Promise((resolve, reject) => {
        let loadedCount = 0;
        const totalImages = urls.length;
        const images = [];

        // 创建一个Image对象并设置onload和onerror事件处理器
        function loadImage(url) {
          const img = new Image();
          img.onload = () => {
            loadedCount++;
            if (loadedCount === totalImages) {
              resolve(true);
            }
          };
          img.onerror = () => {
            reject(new Error(`Failed to load image: ${url}`));
          };
          img.src = url;
          images.push(img);
        }

        // 开始加载所有图片
        urls.forEach(url => loadImage(url));

        // 设置超时处理
        setTimeout(() => {
          if (loadedCount !== totalImages) {
            reject(new Error('Timeout: Images did not load within 10 seconds'));
          }
        }, 10000);
      });
    };
  },
};

export default WaitForImagesPlugin;
<template>
  <section class="x-glly">
    <!-- 渲染内容区域 -->
    <section id="preview-wrapper" ref="previewWrapper">
      <img class="background" @load="loadImg(2)" src="../../assets/imgs/pos2.jpg" alt="" />
      <img class="qrcode" @load="loadImg(1)" :src="qrCode" alt="" />
    </section>

    <!-- 弹窗分享step1 -->
    <section class="share-mask" v-if="showAD">
      <section class="pic">
        <section class="wrapper">
          <img class="shareqr" @click.stop="" src="../../assets/imgs/pos1.jpg" />
          <section class="btn" @click="generatePoster">生成海报并分享</section>
        </section>
        <section class="close" @click="close">✕</section>
      </section>
    </section>

    <!-- 弹窗分享step2 -->
    <section class="poster-mask" v-if="showSharePoster">
      <section class="header" @click="closePoster">
        <section class="close">✕</section>
      </section>
      <section class="wrapper">
        <section class="poster" v-if="!screenshot">
          <van-loading />
        </section>
        <img class="poster-wrapper" v-else :src="screenshot" />
        <section class="btn" @click="tip(1)">分享给好友</section>
        <section class="btn" @click="tip(2)">下载海报</section>
      </section>
    </section>

    <van-popup v-model="showInvitePanel" round :closeable="closeable" :close-on-click-overlay="false" position="bottom">
      <!-- 登录页面 -->
      <section class="invite-wrapper">
        <section class="title">登录格乐利雅婚礼艺术中心</section>
        <section class="input-wrapper mt20" style="margin-top: 40px">
          <input type="number" v-model="uPhone" @input="handleInput($event, 'uPhone')" placeholder="您的手机号" />
          <section class="btn-text" @click="getCode" v-if="!isWaited">{{ $t('XAuth.Phone.309818-3') }}</section>
          <section class="btn-text" v-else>{{ time }}s</section>
        </section>
        <section class="input-wrapper">
          <input type="text" maxlength="6" v-model="code" placeholder="验证码" />
        </section>
        <section class="handle-wrapper flex-r mt10">
          <section class="btn ok" @click="handleBind">立即登录</section>
        </section>
      </section>
    </van-popup>
  </section>
</template>
<script>
import api from '@/Api.js'
export default {
  /******************************************
  ** 手机号授权登录
  * @description 手机号授权登录
  * @author stone
  *****************************************
  prop1          <type>            <属性描述>
  *****************************************/
  name: 'phone_auth',
  components: {},
  props: {},
  data() {
    return {
      time: 60,
      isLogin: false,
      isWaited: false,
      closeable: false,
      disableBtn: false,
      disableInviteBtn: false,
      qrCode: '',
      isSuccess: false,
      url: 'https://www.aiyaopai.com',
      code: '',
      uPhone: '',
      count: 0,
      showSharePanel: false,
      imageData: '',
      screenshot: '',
      loadCount: 0,
      showSharePoster: false,
      loading: true,
      targetCount: 50, // 目标数字
      duration: 1000, // 动画持续时间，单位为毫秒
      showInvitePanel: false, // 显示邀请好友弹窗
      phone: '' // 手机号
    }
  },
  computed: {
    viewCount() {
      return this.$store.state.status.viewCount || 0
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    showAD() {
      return this.$store.state.status.showGGLYAd
    },
    liveId() {
      return this.$route.params.id
    },
    liveInfo() {
      return this.$store.state.liveInfo
    },
    showPreview() {
      return this.showInvitePanel && this.isLogin && !this.isSuccess
    }
  },
  watch: {
    showPreview(n) {
      if (n) {
        setTimeout(() => {
          // this.generate()
        }, 100)
      }
    },

    showSharePoster(n) {
      if (n) {
        document.body.style.height = '100vh'
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.height = ''
        document.body.style.overflow = ''
      }
    },
    userInfo(n) {
      if (n && n.id) {
        this.isLogin = true
      }
      console.log('变了')
      this.loading = false
    }
  },
  methods: {
    close() {
      this.$store.commit('setShowGGLYAd', false)
    },
    loadImg(i) {
      this.loadCount += i
    },
    generatePoster() {
      this.$store.commit('setShowGGLYAd', false)
      if (!this.isLogin) {
        this.showInvitePanel = true
      } else {
        this.generate()
      }
    },

    tip(i) {
      if (i === 1) {
        this.$toast('长按海报分享给朋友')
      } else {
        this.$toast('长按海报保存到相册')
      }
    },
    closePoster() {
      this.showSharePoster = false
      // this.qrCode = ''
      this.screenshot = ''
    },
    async generate() {
      let formId = this.liveInfo.registrationId
      let uId = this.userInfo.account.phoneNo
      this.showSharePoster = true
      try {
        if (formId && uId) {
          this.qrCode = await this.$generateQRCode(
            `https://form.aiyaopai.com/pages/sign-up/sign-up?id=${formId}&tel=${uId}`,
            200
          )
        } else {
          this.qrCode = await this.$generateQRCode(`https://m.topgalleria.com`, 200)
        }
      } catch (error) {
        console.error('Error generating QR code:', error)
      }
      try {
        let timers = setInterval(async () => {
          if (this.loadCount === 3) {
            clearInterval(timers)
            this.screenshot = await this.$captureScreenshot('preview-wrapper')
            // 调用海报生成借口
            const res = await api.recordPoster(this.liveId)
          }
        }, 500)
      } catch (error) {
        console.error('Error capturing screenshot:', error)
      }
    },
    share() {
      // 弹出share弹窗
      this.showSharePanel = true
    },
    startIncrease() {
      const startTime = Date.now() // 记录开始时间
      const stepTime = 10 // 更新频率，单位为毫秒
      this.count = 0 // 开始前重置计数

      const target = this.viewCount // 目标值
      const duration = this.duration // 总时长

      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime // 已经过的时间
        const progress = elapsedTime / duration // 完成的百分比
        const currentCount = target * progress // 根据进度计算当前应有的计数

        if (elapsedTime < duration) {
          this.count = currentCount
        } else {
          this.count = target // 确保最终值等于目标值
          clearInterval(interval) // 停止定时器
        }
      }, stepTime)
    },
    handleInput(event, tag) {
      const input = event.target.value
      if (input.length > 11) {
        this[tag] = input.slice(0, 11)
        return
      }
      this[tag] = input
    },
    show() {
      // this.showInvitePanel = true
      this.showSharePanel = true
    },
    async submitInvite() {
      // 校验手机号和验证码
      const phoneRegex = /^1[3-9]\d{9}$/
      if (!phoneRegex.test(this.phone)) {
        this.$toast('手机号格式有误')
        return false
      }
      try {
        if (!this.disableInviteBtn) {
          this.disableInviteBtn = true
          this.$toast.loading({
            message: '邀请中...',
            forbidClick: true,
            duration: 0 // 持续展示 toast
          })
          const res = await api.initiativeRecommend(this.phone, this.liveId)
          this.disableInviteBtn = false
          if (res.data?.result?.existent) {
            this.$toast.clear()
            this.$toast('该用户已经参与过活动')
            this.phone = ''
          } else {
            setTimeout(() => {
              this.$toast.clear()
            }, 500)
            setTimeout(() => {
              this.isSuccess = true
            }, 500)
          }
        }
      } catch (e) {
        console.log('====error', e)
        this.$toast(e.response.data.message)
        this.disableInviteBtn = false
      }
    },
    async getCode() {
      // 校验手机号
      const phoneRegex = /^1[3-9]\d{9}$/
      if (!phoneRegex.test(this.uPhone)) {
        this.$toast('手机号格式有误')
        return false
      }
      const res = (await api.sendSmsCode(this.uPhone)).data
      if (res.message === 'Success') {
        this.isWaited = true
        const timmer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            this.isWaited = false
            this.time = 60
            window.clearInterval(timmer)
          }
        }, 1000)
      }
    },

    init() {
      let timer = setInterval(() => {
        if (!this.loading) {
          clearInterval(timer)
          if (!this.isLogin) {
            this.showInvitePanel = true
          } else {
            this.$store.commit('setShowGGLYAd', true)
          }
        }
      }, 500)
    },

    closePanel() {
      this.showInvitePanel = false
    },

    /**
     * 处理绑定操作
     */
    async handleBind() {
      // 校验手机号和验证码
      // 校验手机号和验证码
      const phoneRegex = /^1[3-9]\d{9}$/
      if (!phoneRegex.test(this.uPhone) || this.code.length === 0) {
        this.$toast('手机号或验证码格式有误')
        return false
      }
      try {
        if (!this.disableBtn) {
          this.disableBtn = true
          const res = (await api.verifySMSCode(this.uPhone, this.code)).data
          // 拼接token
          const token = `${res.token_type} ${res.access_token}`
          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', res.refresh_token)
          // 存储过期时间
          const tokenExpireTime = Date.now() + res.expires_in * 1000
          localStorage.setItem('expires_in', tokenExpireTime)

          // vuex存储用户信息
          const user = (await api.getCurrentUser()).data
          this.$store.commit('setUserInfo', user)
          // await api.initiativeRecommend('', this.liveId)
          this.disableBtn = false
          this.showInvitePanel = false
          // this.generate()
          this.$store.commit('setShowGGLYAd', true)
        }
      } catch (e) {
        this.$toast(e.response.data.error_description)
        this.disableBtn = false
      }
    }
  },
  mounted() {
    // this.generate()
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.x-glly {
  @media screen and (min-width: 1024px) {
    .van-popup {
      width: 500px;
      left: 50%;
      margin-left: -250px;
    }
  }

  .poster-mask {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    padding: 0px 30px;
    height: 100%;
    overflow: hidden;

    .header {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      color: #fff;

      .close {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border: 0.5px solid #fff;
        margin-top: 20px;
      }
    }

    .wrapper {
      width: 90%;
      .poster {
        width: 100%;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .poster-wrapper {
        width: 100%;
        // min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn {
        width: 100%;
        height: 45px;
        border: 0.5px solid #fff;
        font-size: 14px;
        padding: 15px 0;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        margin-top: 10px;

        &:first-child {
          margin-top: 20px;
        }
      }
    }
  }

  #preview-wrapper {
    width: 100%;
    position: relative;
    // 若要显示渲染区域，注释即可
    position: absolute;
    top: -10000px;

    .background {
      width: 100%;
      display: block;
    }

    .qrcode {
      width: 15.5%;
      height: 10%;
      position: absolute;
      z-index: 1;
      bottom: 7.5%;
      right: 4.8%;
    }
  }

  .share-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;

    .wrapper {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn {
        background: #000;
        width: 100%;
        padding: 15px 20px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        border-radius: 30px;
        color: #fff;
        margin-top: 20px;
      }
    }

    .close {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border: 0.5px solid #fff;
      margin-top: 20px;
    }

    img {
      width: 100%;
    }

    .pic {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .click {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  @media screen and (min-width: 500px) {
    .share-mask {
      .wrapper {
        width: 60%;
      }

      .btn,
      .close {
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 500px) {
    .poster-mask {
      .wrapper {
        width: 400px;
      }

      .btn,
      .close {
        cursor: pointer;
      }
    }
  }
  .invite-wrapper {
    padding: 20px 20px 40px;
    color: #333;
    position: relative;

    .generate-wrapper {
      width: 100%;
    }

    .poster-list {
      margin-top: 20px;
      display: flex;

      .item {
        background: #efefef;
        border-radius: 5px;
        position: relative;
      }
    }

    .share {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      color: #999;
    }

    .close {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 1;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      color: #999;
    }

    .desc {
      color: #9f9f9f;
      font-size: 16px;
      line-height: 26px;
      padding: 20px 27px 40px;
      text-align: center;
    }

    .qrcode {
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
      p {
        color: #9f9f9f;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-top: 5px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 30px;
      text-align: center;
      font-size: 22px;
    }

    .join-count {
      background: #fef5f6;
      color: #c53f46;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      border-radius: 40px;
      height: 40px;
      margin-top: 20px;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .text {
        span {
          background: #ee293d;
          color: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          margin: 0 5px;
        }
      }
    }

    .yhq {
      width: 100%;
    }

    .input-wrapper {
      border: 0.5px solid #e9e9e9;
      width: 100%;
      height: 55px;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      input {
        background: transparent;
        border: none;
        font-size: 16px;
        padding: 5px 5px 5px 20px;
        flex: 1;
      }

      .btn-text {
        height: 45px;
        display: flex;
        width: 150px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: $theme-color;
      }
    }

    .handle-wrapper {
      margin-top: 30px;

      .btn {
        height: 55px;
        border-radius: 5px;
        background: transparent;
        line-height: 55px;
        text-align: center;
        color: #9f9f9f;
        font-size: 18px;
        padding: 0 15px;
      }

      .ok {
        background: $theme-color;
        color: #fff;
        flex: 1;
      }
    }

    .btn-submit {
      background: $theme-color;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      height: 45px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 800;
      cursor: pointer;
    }
  }
}
</style>

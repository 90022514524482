<template>
  <section class="x-player">
    <video
      :id="id"
      width="100%"
      height="100%"
      :poster="poster"
      webkit-playsinline
      playsinline
      x5-playsinline
      class="video-js"
    ></video>
  </section>
</template>
<script>
import Videojs from 'video.js' // 引入Videojs
export default {
  /******************************************
  ** 视频播放器video.js
  * @description video.js
  * @author stone
  *****************************************
  id              string            id
  poster          string            封面
  src             string            视频源
  *****************************************/
  name: 'x-player',
  components: {},
  props: {
    id: {
      default() {
        return 'p_' + parseInt(Math.random() * 1000)
      }
    },
    src: {
      type: String,
      default() {
        return ''
      }
    },
    poster: String
  },
  data() {
    return {}
  },
  computed: {
    newSrc() {
      // if (this.src.indexOf('http://i3.vzan.cc') != -1) {
      //   return this.src.replace('http://i3.vzan.cc', 'https://liveproxy.aiyaopai.com')
      // }
      // if (this.src.indexOf('https://i3.vzan.cc') != -1) {
      //   return this.src.replace('https://i3.vzan.cc', 'https://liveproxy.aiyaopai.com')
      // }
      // if (this.src.indexOf('http://p2.vzan.com') != -1) {
      //   return this.src.replace('http://p2.vzan.com', 'https://liveproxy.aiyaopai.com/p2')
      // }
      // if (this.src.indexOf('https://p2.vzan.com') != -1) {
      //   return this.src.replace('https://p2.vzan.com', 'https://liveproxy.aiyaopai.com/p2')
      // }
      return this.src
    }
  },
  methods: {
    initVideo() {
      console.log('=========', this.newSrc)
      // 这些options属性也可直接设置在video标签上，见 muted
      let options = {
        autoplay: false, // 设置自动播放
        controls: true, // 显示播放的控件
        language: 'zh-CN',
        fluid: true,
        muted: false,
        nativeControlsForTouch: false,
        errorDisplay: false, // 禁用错误消息的显示
        sources: [
          // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
            src: this.newSrc
            // type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
          }
        ]
      }
      // videojs的第一个参数表示的是，文档中video的id
      const myPlyer = Videojs(this.id, options, function onPlayerReady() {
        // console.log('onPlayerReady 中的this指的是：', this) // 这里的this是指Player,是由Videojs创建出来的实例
        // console.log(myPlyer === this) // 这里返回的是true
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.src) {
        this.initVideo()
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.x-player {
  width: 100%;
  // height: 100%;
}
</style>
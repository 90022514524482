import axios from 'axios'
import qs from 'qs'

axios.defaults.withCredentials = true

const services = axios.create({
  withCredentials: false,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
    AccessControlAllowOrigin: '*'
  }
})

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

services.interceptors.request.use(
  (config) => {
    console.log(config.url)
    //只针对get方式进行序列化
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    // 检查URL是否是一个完整的URL
    if (!config.url.startsWith('http://') && !config.url.startsWith('https://')) {
      if (!config.curbsUrl) {
        config.url = process.env.VUE_APP_BASE_API + config.url
      } else {
        config.url = config.curbsUrl + config.url
      }
    }
    const token = localStorage.getItem('access_token')
    config.headers.Authorization = token
    return config
  },
  (err) => Promise.reject(err)
)

services.interceptors.response.use(
  (res) => {
    return Promise.resolve(res)
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      //清除缓存
      // store.commit('user/logOut', false)
      //登录
      // store.commit('user/toWxLogin')
    }
    return Promise.reject(err)
  }
)

// 将services抛出去
export default services

<template>
  <section class="information-container">
    <!-- 图文矩阵 -->
    <section class="wrapper">
      <template v-for="(item, index) in list">
        <!-- 大图模式 -->
        <section
          class="max-wrapper react"
          :key="index"
          :style="{ width: maxW + 'px' }"
          v-if="(!item.hidden && item.large) || (item.content && (item.content.title || item.content.content))"
        >
          <section class="like-wrapper" v-show="item.likes > 0">
            <!-- 点赞数量 -->
            <x-like :enableClick="false" :id="item.id" :count="item.likes" :state="item.likeState"></x-like>
          </section>

          <!-- 外链 -->
          <section
            class="link"
            v-if="item.content && item.content.linkTitle && item.content.linkUrl"
            @click="linkTo(item.content.linkUrl)"
          >
            <x-icon type="link"></x-icon>
            {{ item.content.linkTitle }}
          </section>
          <!-- 视频播放器 -->
          <x-player
            v-if="item && item.content && item.content.videoUrl && !checkMode"
            :src="item.content.videoUrl"
            :poster="item.url"
          ></x-player>
          <!-- 大图显示 -->
          <x-image
            :src="item.wUrl"
            v-else
            @click.native="preview(index)"
            :enableWatermark="enableWatermark"
            :checked="item.isChecked"
            :checkMode="checkMode"
            :width="maxW"
            :height="getH(item.width, item.height)"
            @onChecked="check(index)"
          ></x-image>
          <section class="info-wrapper">
            <h1 class="title" v-if="item && item.content && item.content.title">
              {{ item.content.title }}
            </h1>
            <p class="desc" v-if="item && item.content && item.content.content">
              {{ item.content.content }}
            </p>
          </section>
        </section>

        <!-- 小图模式 -->
        <template v-else>
          <section class="min-wrapper react" :key="index" v-if="!item.hidden">
            <section class="like-wrapper" v-show="item.likes > 0">
              <!-- 点赞数量 -->
              <x-like :enableClick="false" :id="item.id" :count="item.likes" :state="item.likeState"></x-like>
            </section>
            <x-image
              :src="item.wUrl"
              :enableWatermark="enableWatermark"
              :checked="item.isChecked"
              :width="minPhotoWidth"
              :height="minPhotoWidth"
              :checkMode="checkMode"
              @click.native="preview(index)"
              @onChecked="check(index)"
            ></x-image>
          </section>
        </template>
      </template>
    </section>
  </section>
</template>
<script>
import XPlayer from '@/components/XPlayer/Index'
import XImage from '@/components/XImage/Index'
import XLike from '@/components/XLike/Index'
export default {
  /******************************************
  ** 信息流组件
  * @description 大图小图+文字组合
  * @author stone
  *****************************************
  list              Array            列表
  *****************************************/
  name: 'layout_information',
  components: {
    XImage,
    XPlayer,
    XLike
  },
  props: {
    list: Array,
    checkMode: Boolean
  },
  data() {
    return {}
  },
  computed: {
    minPhotoWidth() {
      return (window.innerWidth - 8) / 3
    },

    noWatermark() {
      return this.$route.query.wm === 'none'
    },

    enableWatermark() {
      return this.$store.state.config.enableWatermark && !this.noWatermark
    },
    maxW() {
      return window.innerWidth
    },
    checkPhotoList() {
      return this.$store.state.checkPhotoList
    }
  },
  methods: {
    preview(index) {
      this.$emit('preview', index)
    },
    linkTo(url) {
      window.location.href = url
    },
    check(index) {
      if (!this.list[index].isChecked) {
        if (this.checkPhotoList.length < 9) {
          // 添加照片
          let arr = this.checkPhotoList
          arr.push(this.list[index])
          this.$store.commit('setCheckPhotoList', arr)
          this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
        } else {
          alert(this.$t('XLayout.Information.989242-0'))
        }
      } else {
        // 移除照片
        let arr = this.checkPhotoList
        let newArr = arr.filter((item) => {
          return item.id !== this.list[index].id
        })
        this.$store.commit('setCheckPhotoList', newArr)
        this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
      }
    },
    getH(w, h) {
      return (h / w) * this.maxW
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.information-container {
  width: 100%;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 103%;

    .max-wrapper {
      position: relative;

      .like-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        padding: 5px 10px;
        background: #0000002c;
        color: #fff;
        z-index: 1;
        border-radius: 3px;
      }
      .link {
        position: absolute;
        right: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 12px;
        color: #fff;
        z-index: 9;
      }
      .info-wrapper {
        padding: 10px 0;
        .title {
          // margin: 15px 0;
          line-height: 30px;
          font-weight: 800;
          padding-right: 10px;
          padding-left: 10px;
          word-break: break-all;
          word-wrap: break-word;
          position: relative;
          text-align: left;
          overflow: hidden;
          text-overflow: 'more';
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          font-size: 18px;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            left: 0;
            width: 2px;
            height: 18px;
            background: $theme-color;
          }
        }
        .desc {
          font-size: 13px;
          line-height: 30px;
          padding-right: 10px;
          word-break: break-all;
          word-wrap: break-word;
          text-align: left;
          position: relative;
          padding-left: 10px;
        }
      }
    }
    .min-wrapper {
      margin-bottom: 4px;
      margin-right: 4px;
      position: relative;

      .like-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        padding: 5px 10px;
        background: #0000002c;
        color: #fff;
        z-index: 1;
        border-radius: 3px;
      }
    }
  }
}
</style>

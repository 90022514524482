<template>
  <section
    class="image-container"
    :style="{ backgroundImage: `${showAd ? 'url(https://assets.aiyaopai.com/logo_bg_grey.png)' : ''}` }"
  >
    <!-- checkMode -->
    <section class="check-mask" v-if="checkMode" :class="{ 'mask-checked': checked }" @click.stop="check">
      <section class="btn">
        <x-icon class="nochecked" type="checkbox-blank-circle-fill" v-if="!checked"></x-icon>
        <x-icon class="checked" type="checkbox-circle-fill" v-else></x-icon>
      </section>
    </section>

    <!-- 懒加载 -->
    <template v-if="isLazy">
      <!-- 背景图片 -->
      <section v-if="width || height" class="wrapper bg" v-lazy:background-image="newSrc" :style="bgStyle">
        <!-- <span style="background: #000; color: #fff; font-size: 12px; height: 22px; padding: 5px">{{
          parseInt(height)
        }}</span> -->
      </section>

      <!-- 图片 -->
      <section v-else class="wrapper">
        <img v-lazy="newSrc" :style="style" alt="" />
      </section>
    </template>

    <!-- 非懒加载 -->
    <template v-else>
      <!-- 背景图片 -->
      <section v-if="width || height" class="wrapper bg" :style="bgStyleTemp"></section>

      <!-- 图片 -->
      <section v-else class="wrapper">
        <img :src="newSrc" :style="style" alt="" />
      </section>
    </template>

    <slot></slot>
  </section>
</template>
<script>
export default {
  /******************************************
  ** 图片组件
  * @description 图片组件
  * @author name
  *****************************************
  src          String             路径
  width        Number             图片宽度
  height       Number             图片高度
  enableWatermark Boolean           是否显示水印
  checkMode    Boolean            是否显示check模式
  checked      Boolean            是否check
  isLazy       Boolean            是否懒加载
  *****************************************/
  name: 'x_image',
  components: {},
  props: {
    src: String,
    width: Number,
    height: Number,
    enableWatermark: {
      type: Boolean,
      defalut() {
        return false
      }
    },
    isLazy: {
      type: Boolean,
      default() {
        return false
      }
    },
    checkMode: Boolean,
    checked: Boolean
  },
  data() {
    return {
      style: {
        width: '100%',
        display: 'block'
      },
      checkList: []
    }
  },
  computed: {
    temp() {
      return this.$store.state.liveInfo.tempObj ? this.$store.state.liveInfo.tempObj.temp || {} : {}
    },
    showAd() {
      return !this.temp.conceal
    },
    newSrc() {
      let url = this.src
      let re = /(imageMogr2\/)([^|]*)/g // 正则表达式匹配imageMogr2后到下一个'|'之前的部分

      if (url.match(re)) {
        // 如果url中包含'imageMogr2/'，则替换'imageMogr2/'后到'|'之前的部分为'thumbnail/1200x'
        url = url.replace(re, '$1auto-orient/thumbnail/1200x')
      } else {
        // 如果url中不包含'imageMogr2/'，则在url参数部分后面添加'&imageMogr2/thumbnail/1200x|imageslim'
        let index = url.indexOf('&')
        url = url.slice(0, index + 1) + 'imageMogr2/auto-orient/thumbnail/1200x|imageslim&' + url.slice(index + 1)
      }

      return url
    },
    bgStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover'
      }
    },
    bgStyleTemp() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        backgroundImage: `url(${this.newSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover'
      }
    }
  },
  methods: {
    check() {
      this.$emit('onChecked')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common/theme.scss';
.image-container {
  display: block;
  position: relative;
  background-color: #efefef;
  background-position: center center;
  background-size: 70%;
  background-repeat: no-repeat;

  .check-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .btn {
      position: absolute;
      right: 5px;
      bottom: 5px;
      font-size: 20px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nochecked {
      color: #fff;
    }
    .checked {
      color: $theme-color;
    }
  }
  .mask-checked {
    background: rgba(255, 255, 255, 0.4);
  }
  .bg {
    // background-color: #efefef;
  }
  .wrapper {
    display: flex;
  }
}
</style>

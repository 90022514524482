import { render, staticRenderFns } from "./Intellect.vue?vue&type=template&id=c4326452&scoped=true&"
import script from "./Intellect.vue?vue&type=script&lang=js&"
export * from "./Intellect.vue?vue&type=script&lang=js&"
import style0 from "./Intellect.vue?vue&type=style&index=0&id=c4326452&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4326452",
  null
  
)

export default component.exports
import html2canvas from 'html2canvas';

const Html2CanvasPlugin = {
  install(Vue) {
    Vue.prototype.$captureScreenshot = async function (domId) {
      const element = document.getElementById(domId);
      if (!element) {
        throw new Error(`Element with id ${domId} not found`);
      }

      const scale = window.devicePixelRatio || 3 // 使用设备像素比例
      const width = element.offsetWidth
      const height = element.offsetHeight

      await new Promise((resolve) => {
        if (document.readyState === 'complete') {
          resolve();
        } else {
          window.addEventListener('load', resolve);
        }
      });

      const canvas = await html2canvas(element, { scale: scale, width: width, height: height });
      return canvas.toDataURL('image/png');
    };
  },
};

export default Html2CanvasPlugin;
// qrcode-plugin.js
import QRCode from 'qrcode';

const QrCodePlugin = {
  install(Vue) {
    Vue.prototype.$generateQRCode = async function (url, size) {
      return new Promise((resolve, reject) => {
        try {
          QRCode.toDataURL(url, {
            width: size
          }).then(qurl => {
            resolve(qurl)
          }).catch(e => {
            reject(new Error('Failed to generate QR code'));
          })
        } catch (error) {
          reject(error);
        }
      });
    };
  },
};

export default QrCodePlugin;

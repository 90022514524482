import Vue from 'vue'
import App from './App.vue'
import api from '@/Api.js'
import store from './store'
import defaultRouter from './router/default'
import simpleRouter from './router/simple'
import '@/styles/common/index.scss' // 引入全局样式
import VueLazyload from 'vue-lazyload'
import { Toast, Popup, Empty, ActionSheet, Loading } from 'vant'
import XIcon from '@/components/XIcon/Index.vue'
import XPreview from '@/components/XPreview/Index.vue'
import VueI18n from 'vue-i18n'
import Html2CanvasPlugin from './utils/html2canvas-plugin.js';
import QrCodePlugin from './utils/qrcode-plugin.js';
import WaitForImagesPlugin from './utils/waitForImagesPlugin.js';
import "./plugins/video.js";
// import VConsole from 'vconsole';

// const vConsole = new VConsole();

; (async () => {
  Vue.use(Loading)
  Vue.use(Popup)
  Vue.use(Toast)
  Vue.use(Empty)
  Vue.use(Html2CanvasPlugin);
  Vue.use(QrCodePlugin);
  Vue.use(WaitForImagesPlugin);

  Vue.use(ActionSheet)
  // 图片懒加载插件
  Vue.use(VueLazyload, {
    preLoad: 1,
    attempt: 10
  })
  Vue.component('XIcon', XIcon)
  Vue.component('XPreview', XPreview)
  Vue.config.productionTip = false

  // i18N处理
  Vue.use(VueI18n)
  const messages = {}
  const files = require.context('@/i18n/temp/', true, /\.json$/) // 导入locales目录下的所有.json文件
  files.keys().forEach(key => {
    const lang = key.split('/')[1].split('.')[0] // 获取文件名中的语言代码
    messages[lang] = Object.assign({}, messages[lang], files(key)) // 将内容合并到messages对象中对应的语言项中
  })
  const result = Object.values(messages).reduce((acc, curr) => {
    Object.entries(curr).forEach(([key, value]) => {
      acc[key] = acc[key] || {};
      acc[key] = { ...acc[key], ...value };
    });
    return acc;
  }, {});

  let i18n;

  // 查询接口跳转路径
  let tempObj = {}
  let themeInfo = {}
  let router = defaultRouter

  try {
    const getLiveInfo = async () => {
      if (window.location.href.indexOf('/live/') !== -1) {
        let liveId = window.location.href.split('/live/')[1].split('/')[0]
        if (liveId.indexOf('?') !== -1) {
          liveId = liveId.split('?')[0]
        }
        const res = (await api.getLiveInfo(liveId)).data
        // 判断默认语言，如果localstorage中有默认语言，就不改变，如果没有，就设置默认语言
        let lang;
        if (window.localStorage.getItem(`lang-${liveId}`) === null) {
          try {
            console.log('----1111', res.templates)
            const templates = JSON.parse(res.templates);
            console.log('22222', templates)
            lang = templates.languageInfo ? templates.languageInfo.value === 'ZH' ? 'zh' : 'en' : 'zh';
          } catch (e) {
            console.error(e); // 可以记录错误信息
            lang = 'zh'; // 或者设置默认值
          }
        } else {
          lang = window.localStorage.getItem(`lang-${liveId}`)
        }
        i18n = new VueI18n({
          locale: lang, // 默认语言为中文
          messages: result
        })

        Vue.prototype.$i18nc = i18n

        return res
      } else {
        return {}
      }
    }


    if (window.location.href.indexOf('/live/') !== -1) {
      let res = await getLiveInfo()
      let menuList = JSON.parse(res.templates).customizeMenuInfo
      let showAllCategories = JSON.parse(res.templates).showAllCategories
      store.commit('setMenuAll', showAllCategories ? showAllCategories.enable : true)
      if (menuList && menuList.enable) {
        // 启用了菜单显示
        // console.log('====启用了menu', menuList)
        // 遍历menuList.categoryOptions，返回一个新的数组，每项中的enable=true
        menuList.categoryOptions = menuList.categoryOptions.filter((item) => {
          return item.enable
        })
        menuList.categoryOptions.map((item, index) => {
          // console.log(item.type, item)
          if (item.type === 'default') {
            // 兼容前台传值错误的问题
            if (item.label.indexOf('详情') !== -1) {
              item.type = 'detail'
              item.checked = false
            } else {
              item.type = 'photo'
              item.checked = true
            }
          } else {
            if (index === 0) {
              item.checked = true
            } else {
              item.checked = false
            }

            if (item.description) {
              item.type = 'article'
            }
            if (item.link) {
              item.type = 'frame'
            }
          }
        })
        store.commit('setMenuList', menuList.categoryOptions)
      } else {
        store.commit('setMenuList', [
          {
            enable: true,
            label: '活动详情',
            type: 'detail',
            checked: false,
          },
          {
            enable: true,
            label: '照片直播',
            type: 'photo',
            checked: true,
          },
          {
            enable: false,
            label: '互动',
            type: 'interact',
            checked: false,
          },
        ])
      }
      if (res.id) {
        tempObj = JSON.parse(res.templates) || {}
        store.commit('setCurrentPhotoSortOrder', tempObj.temp ? tempObj.temp.defaultPhotoSort : 'Descending')
        let launchType = tempObj.launchScreen ? tempObj.launchScreen.showType : ''
        store.commit('setLaunchFull', launchType === 'full' || launchType === 'scale')
        themeInfo = tempObj.themeInfo || {}
        if (!themeInfo || themeInfo.themeType === 'simple_light') {
          // 没有匹配到主题，则加载Simple主题
          store.commit('setTheme', 'simple')
          router = simpleRouter
        } else {
          store.commit('setTheme', 'default')
          router = defaultRouter
        }
      } else {
      }
    }
  } catch (e) {
    console.log('error', e)
  }

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')
})()

<template>
  <section class="x-like">
    <section class="like line" @click="handleLike">
      <img class="icon" v-if="!likeState" src="https://assets.aiyaopai.com/like.png" />
      <img class="icon" v-else src="https://assets.aiyaopai.com/liked.png" /> {{ num }}
    </section>
  </section>
</template>
<script>
import api from '@/Api'
export default {
  /******************************************
   ** 点赞组件
   * @author stone
   *****************************************/
  name: 'x-like',
  components: {},
  props: {
    id: String,
    enableClick: {
      type: Boolean,
      default() {
        return true
      }
    },
    count: {
      type: Number,
      default() {
        return 0
      }
    },
    state: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      num: 0,
      likeState: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    state() {
      this.num = this.count
      this.likeState = this.state
    },
    count() {
      this.num = this.count
      this.likeState = this.state
    }
  },
  methods: {
    handleLike() {
      if (this.enableClick) {
        if (this.likeState) {
          this.unlike()
        } else {
          this.like()
        }
      }
    },
    like() {
      if (!this.userInfo.id) {
        this.$doAuth()
      } else {
        if (this.likeState) {
          this.unlike()
        } else {
          const res = api.likeLivePicture(this.id)
          this.likeState = true
          this.num++
          this.$emit('like')
        }
      }
    },
    unlike() {
      if (!this.userInfo.id) {
        this.$doAuth()
      } else {
        if (!this.likeState) {
          this.like()
        } else {
          const res = api.unlikeLivePicture(this.id)
          this.likeState = false
          this.num--
          this.$emit('unlike')
        }
      }
    }
  },
  mounted() {
    this.num = this.count
    this.likeState = this.state
  }
}
</script>
<style lang="scss" scoped>
.x-like {
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .line {
    display: flex;
    align-items: center;
  }
  .red {
    color: #d33c1e;
  }
}
</style>

<template>
  <section class="waterfall-container">
    <!-- 左侧列表 -->
    <section class="col left-panel" :style="{ width: picWidth }">
      <!-- 图片组件 -->
      <section class="img-wrapper" v-for="leftItem in leftList" :key="leftItem.index">
        <section class="like-wrapper" v-show="leftItem.likes > 0">
          <!-- 点赞数量 -->
          <x-like :enableClick="false" :id="leftItem.id" :count="leftItem.likes" :state="leftItem.likeState"></x-like>
        </section>

        <x-image
          v-if="!leftItem.hidden"
          @click.native="preview(leftItem.index)"
          :src="leftItem.wUrl"
          :enableWatermark="enableWatermark"
          :checkMode="checkMode"
          :width="getW"
          :isLazy="true"
          :height="getH(leftItem.width, leftItem.height)"
          :checked="list[leftItem.index].isChecked"
          @onChecked="check(leftItem.index)"
        ></x-image>
      </section>
    </section>
    <!-- 右侧列表 -->
    <section class="col right-panel" :style="{ width: picWidth }">
      <!-- 图片组件 -->
      <section class="img-wrapper" v-for="rightItem in rightList" :key="rightItem.index">
        <section class="like-wrapper" v-show="rightItem.likes > 0">
          <!-- 点赞数量 -->
          <x-like
            :enableClick="false"
            :id="rightItem.id"
            :count="rightItem.likes"
            :state="rightItem.likeState"
          ></x-like>
        </section>
        <x-image
          v-if="!rightItem.hidden"
          @click.native="preview(rightItem.index)"
          :src="rightItem.wUrl"
          :enableWatermark="enableWatermark"
          :checkMode="checkMode"
          :width="getW"
          :isLazy="true"
          :height="getH(rightItem.width, rightItem.height)"
          :checked="list[rightItem.index].isChecked"
          @onChecked="check(rightItem.index)"
        ></x-image>
      </section>
    </section>
  </section>
</template>
<script>
import XLike from '@/components/XLike/Index'
import XImage from '@/components/XImage/Index'
export default {
  /******************************************
  ** 瀑布流容器
  * @description 瀑布流容器
  * @author stone
  *****************************************
  list          Array            数组
  *****************************************/
  name: 'waterfall_layout',
  components: {
    XImage,
    XLike
  },
  props: {
    list: Array,
    checkMode: Boolean
  },
  data() {
    return {
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0
    }
  },
  watch: {
    list() {
      this.initList()
    }
  },
  computed: {
    picWidth() {
      return (window.innerWidth - 4) / 2 + 'px'
    },
    noWatermark() {
      return this.$route.query.wm === 'none'
    },

    enableWatermark() {
      return this.$store.state.config.enableWatermark && !this.noWatermark
    },
    getW() {
      return (window.innerWidth - 4) / 2
    },
    checkPhotoList() {
      return this.$store.state.checkPhotoList
    }
  },
  methods: {
    initList() {
      this.init()
      if (this.list.length > 0) {
        for (let index = 0; index < this.list.length; index++) {
          const item = this.list[index]
          if (item.hidden) continue
          if (this.leftHeight <= this.rightHeight) {
            this.$set(item, 'index', index)
            this.leftList.push(item)
            this.leftHeight += this.getH(item.width, item.height)
          } else {
            this.$set(item, 'index', index)
            this.rightList.push(item)
            this.rightHeight += this.getH(item.width, item.height)
          }
        }
      }
    },
    getH(w, h) {
      // return (h / w) * ((window.innerWidth - 4) / 2)
      if (w > h) {
        return (window.innerWidth - 4) / 2 / 1.5
      } else {
        return ((window.innerWidth - 4) / 2 / 1.5) * 2 + 4
      }
    },
    init() {
      this.leftList = []
      this.rightList = []
      this.leftHeight = 0
      this.rightHeight = 0
    },
    check(index) {
      if (!this.list[index].isChecked) {
        if (this.checkPhotoList.length < 9) {
          // 添加照片
          let arr = this.checkPhotoList
          arr.push(this.list[index])
          this.$store.commit('setCheckPhotoList', arr)
          this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
        } else {
          alert(this.$t('XLayout.Waterfall.011931-0'))
        }
      } else {
        // 移除照片
        let arr = this.checkPhotoList
        let newArr = arr.filter((item) => {
          return item.id !== this.list[index].id
        })
        this.$store.commit('setCheckPhotoList', newArr)
        this.$set(this.list[index], 'isChecked', !this.list[index].isChecked)
      }
    },
    preview(index) {
      this.$emit('preview', index)
    }
  },
  mounted() {
    this.initList()
    window.addEventListener('scroll', () => {
      // console.log('--左：' + this.leftHeight + '  --右' + this.rightHeight)
    })
  }
}
</script>
<style lang="scss" scoped>
.waterfall-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .col {
    .img-wrapper {
      margin-bottom: 4px;
      position: relative;

      .like-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        padding: 5px 10px;
        background: #0000002c;
        color: #fff;
        z-index: 1;
        border-radius: 3px;
      }
    }
  }
}
</style>
